.faq-container {
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 40px;
    max-width: 1920px;
}

.faq-item {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 25px;
    cursor: pointer;
    border: none;
    border-bottom: 0.1px solid #AAA;
    display: grid;
    grid-template-columns: 1fr 0.1fr;
    transition: border-bottom 1s ease;

}

.question {
    font-weight: bold;
    color: #AAA;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.answer {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 0;
    overflow: hidden;
    margin-top: 10px;
    transition: max-height 0.5s ease;
}

.answer.open {
    max-height: 1000px;
    margin-top: 10px;
    transition: max-height 2s ease;
}

.answer.close {
    max-height: 0;
    transition: max-height 0.5s ease-out;
}


.faq-item svg {
    width: 20px !important;
    height: 22px !important;
    margin-left: auto;
    cursor: pointer;
    margin-top: 5px;
    stroke: #AAA;

}



.arrow {
    width: 40px;
    height: 40px;
    stroke: #AAA;
    cursor: pointer;
}

.arrow.open {
    stroke: #0F0;
    stroke-width: 2.6px;
    transform: rotate(135deg);
    transition: transform 0.5s ease, stroke 1s ease;
}

.arrow.close {
    stroke: transparent;
    transform: rotate(0deg);
    transition: transform 0.5s ease, stroke 1s ease;
}



@media screen and (min-width:1051px) and (max-width:1300px) {



    .question {
        font-size: 20px;
    }

    .answer {
        font-size: 22px;
    }
}

@media screen and (min-width:831px) and (max-width:1050px) {




    .question {
        font-size: 18px;
    }

    .answer {
        font-size: 16px;
    }
}

@media screen and (min-width:601px) and (max-width:830px) {



    .question,
    .answer {
        font-size: 16px;

    }

    .answer {
        line-height: 24px;
    }
}

@media screen and (min-width:481px) and (max-width:600px) {



    .question,
    .answer {
        font-size: 14px;
    }

    .answer {
        font-size: 14px;
        line-height: normal;
    }
}

@media screen and (min-width:100px) and (max-width:480px) {




    .faq-item svg {
        width: 10px !important;
        height: 12px !important;
    }

    .question,
    .answer {
        font-size: 14px;
    }

    .answer {
        font-size: 14px;
        line-height: normal;
    }
}