.container {
    width: 100%;
    display: flex;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    transition: 0.2s;
    margin: auto;
    position: fixed;
    background-color: #0F0;
    margin-top: min(13vh, 130px);
    z-index: 90;
}

.Header_Menu {
    width: 92%;
    height: 100%;
}

.Scroll-Button-mobile-left {
    display: none;
}

.Scroll-Button-mobile-Right {
    display: none;
}

.wrapper {
    width: 100%;
    height: 45px;
    margin: auto;
    display: flex;
    overflow-x: auto;
    max-width: 1920px;
}

.wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.Menu_Box {
    min-width: 5%;
    height: 30px;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.Active {
    text-decoration: none;
    text-align: center;
    color: #101310;
    font-size: 16px;
}

.Menu_Heading p:hover {
    color: #101310;
    font-weight: 700;
}

.Active.active {
    color: #101310;
    font-weight: 700;
}

@media screen and (min-width:1071px) and (max-width:1300px) {

    .container {
        height: auto;
        display: flex;
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        transition: 0.2s;
        margin: auto;
        position: fixed;
        background-color: #0F0;
        margin-top: min(130px, 13.22vh);
    }

    .Header_Menu {
        width: 92%;
        height: 100%;
    }

    .Scroll-Button-mobile-left {
        display: none;
    }

    .Scroll-Button-mobile-Right {
        display: none;
    }

    .wrapper {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        overflow-x: auto;
    }

    .wrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .Menu_Box {
        min-width: 10%;
        height: 45px;
        text-align: center;
        align-items: center;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .Active {
        text-decoration: none;
    }

    .Menu_Box p:hover {
        color: #101310;
        font-weight: 700;
    }

    .Menu_Box p:active {
        color: #101310;
        font-weight: 700;
    }

    .Menu_Heading {
        width: 100%;
        height: auto;
    }

    .Menu_Heading p {
        color: #565656;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}




@media screen and (min-width:800px) and (max-width:1071px) {

    .container {
        height: auto;
        display: flex;
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        transition: 0.2s;
        margin: auto;
        position: fixed;
        background-color: #0F0;
        margin-top: min(100px, 10.22vh);
    }

    .Header_Menu {
        width: 92%;
        height: 100%;
    }

    .Scroll-Button-mobile-left {
        display: none;
    }

    .Scroll-Button-mobile-Right {
        display: none;
    }

    .wrapper {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        overflow-x: auto;
    }

    .wrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .Menu_Box {
        min-width: 10%;
        height: 45px;
        text-align: center;
        align-items: center;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .Active {
        text-decoration: none;
    }

    .Menu_Box p:hover {
        color: #101310;
        font-weight: 700;
    }

    .Menu_Box p:active {
        color: #101310;
        font-weight: 700;
    }

    .Menu_Heading {
        width: 100%;
        height: auto;
    }

    .Menu_Heading p {
        color: #565656;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}





@media screen and (min-width:260px) and (max-width:799px) {
    .Bg_Color {
        width: 100%;
        height: 45px;
    }



    .container {
        width: 100%;
        height: 40px;
        margin-top: 86px;
        position: fixed;
    }

    .Header_Menu {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .Scroll-Button-mobile-left {
        display: block;
        width: 6%;
        height: 100%;
        display: flex;
    }

    .Scroll-Button-mobile-left:hover {
        border-right: 1px solid rgb(202, 201, 201);
    }

    .Scroll-Button-mobile-left button {
        border: none;
        margin: auto;
        height: 100%;
        background-color: transparent;
    }

    .Scroll-Button-mobile-left img {
        width: 10px;
    }

    .Scroll-Button-mobile-Right {
        display: block;
        width: 6%;
        height: 100%;
        display: flex;
    }

    .Scroll-Button-mobile-Right:hover {
        border-left: 1px solid rgb(202, 201, 201);
    }

    .Scroll-Button-mobile-Right button {
        border: none;
        margin: auto;
        background-color: transparent;
        height: 100%;
    }

    .Scroll-Button-mobile-Right img {
        width: 10px;
    }

    .wrapper {
        width: 88%;
        height: 40px;
        margin: auto;
        display: flex;
        align-items: center;
    }

    .wrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .Menu_Box {
        min-width: 200px;
        height: 100%;
        margin: 1px;
    }

    .Menu_Box a {
        text-decoration: none;
    }

    .Menu_Heading {
        width: 100%;
        height: auto;
    }

    .Menu_Heading p {
        font-size: 12px;
        margin-top: 0px;
    }
}