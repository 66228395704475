.SVG_Container_M {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;

}

.UP_M p {
  width: 47%;
  color: #AAA;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  margin: 0;
  top: -27px;
  left: 110%;

}

.DOWN_M p {
  width: 135%;
  color: #AAA;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  margin: 0;
  top: -27px;
  right: 220%;
  text-align: end;
}


.path-animation-UP_M,
.path-animation-UP1,
.path-animation-UP2,
.path-animation-UP3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 620;
  animation: dash1 0.5s ease-in forwards;
}

.path-animation-UP1 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 1s;
  stroke: #010101;
}

.path-animation-UP2 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 2s;
  stroke: #010101;
}

.path-animation-UP3 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 3s;
  stroke: #010101;

}

@keyframes changeStrokeColor {
  to {
    stroke: #AAA;

  }
}

@keyframes dash1 {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 620;
  }
}



.path-animation-DOWN_M,
.path-animation-DOWN1,
.path-animation-DOWN2 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash2 0.5s ease-in forwards;
}

@keyframes dash2 {
  to {
    stroke-dashoffset: 620;

  }
}

.path-animation-DOWN_M {
  animation-delay: 0.5s;
}

.path-animation-DOWN1 {
  animation-delay: 1.5s;
}

.path-animation-DOWN2 {
  animation-delay: 2.5s;
}

.PATHUP_M {
  transform: scaleX(-1);
  margin-left: 140px;
  margin-top: -10px;

}


.PATHDOWN_M {
  margin-left: -95px;
  margin-top: -10px;
}

.UP_M,
.DOWN_M {
  width: auto;
  height: auto;
  position: relative;
}

.UP_M img,
.DOWN_M img {
  width: auto;
  height: auto;
  position: absolute;
  opacity: 0;

}

.UP_M img {
  top: 54%;
  left: 46%;
}

.DOWN_M img {
  top: 54%;
  left: 40%;
}



@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.Frst {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 0.5s;
}

.Sec {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 1s;
}

.Tri {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 1.5s;
}

.For {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 2s;
}

.Fiv {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 2.5s;
}

.Six {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 3s;
}

.Sev {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 3.5s;
}

@media screen and (min-width:581px) and (max-width:760px) {

  .PATHUP_M,
  .PATHDOWN_M {
    width: 170px;
    height: 150px;
  }

  .PATHUP_M {
    margin-left: 80px;
    margin-top: -25px;
  }

  .PATHDOWN_M {
    margin-left: -120px;
    margin-top: -25px;
  }

  .UP_M img,
  .DOWN_M img {
    width: 47px;
    height: 42px;
  }

  .UP_M img {
    top: 48%;
    left: 37%;
  }

  .DOWN_M img {
    top: 48%;
    left: -13%;
  }

  .UP_M p {
    top: -50px;
    font-size: 13px;
  }

  .DOWN_M p {
    top: -50px;
    width: 210%;
    right: 410%;
    font-size: 13px;

  }

}

@media screen and (min-width:260px) and (max-width:580px) {

  .SVG_Container_M {
    margin-top: 60px;
  }

  .PATHUP_M,
  .PATHDOWN_M {
    width: 100px;
    height: 170px;
  }

  .PATHUP_M {
    margin-left: 40px;
    margin-top: -97px;
  }

  .PATHDOWN_M {
    margin-left: -80px;
    margin-top: -97px;
  }

  .UP_M img,
  .DOWN_M img {
    width: 30px;
    height: 25px;
  }

  .UP_M img {
    top: -6%;
    left: 32%;
  }

  .DOWN_M img {
    top: -6%;
    left: -70%;
  }

  .UP_M p {
    width: 46%;
    top: -125px;
    font-size: 14px;
    left: 105%;
    text-align: inherit;
  }

  .DOWN_M p {
    top: -125px;
    width: 210%;
    right: 640%;
    font-size: 14px;
    text-align: end;

  }

}