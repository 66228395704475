* {
    scroll-behavior: smooth;
    font-family: "Open Sans";

}

body::-webkit-scrollbar {
    display: none;
}


.custom-file-input {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.custom-file-label {
    width: 100%;
    font-weight: 600 !important;
}

.custom-file-icon {
    width: 20px;
    height: 20px;
    margin-left: 30px;
    vertical-align: middle;
    cursor: pointer;
}

.Inputfield h6 {
    color: #0f0;
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
    grid-row: 2;
    grid-column: 3;
}

.error {
    color: #0F0 !important;
}

input[type="file"] {
    display: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Main {
    width: auto;
    height: auto;
    margin: auto;
    background-color: #010101;
}

.Logo_Container {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;

}

.Logo_Container img {
    width: 480px;
    height: 64px;
}

.text {
    width: 85%;
    margin: auto;
    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 100px;
}

.Form_Block {
    width: auto;
    height: auto;
    margin: auto;
    max-width: 1920px;
    padding-bottom: 120px;


}

.Forms {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: #010101;
}

.Form_Block h1 {
    color: #0F0;
    font-size: 38px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: normal;
    margin-top: 100px;
}

.Form_Block h2 {
    color: #AAA;
    width: 65%;
    margin: auto;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;

}

.Form_Enroll {
    width: 85%;
    height: auto;
    margin: auto;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-color: #1A1A1A;
    border-radius: 20px;
    border: 1px solid #4B4B4B;
    padding: 20px 20px;
}


.Inputfield {
    width: auto;
    height: 60px;
    display: grid;
    grid-template-columns: 0.7fr 0.5fr 0.5fr;
}

.Inputfield h3 {
    color: #AAA;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

.Inputfield span {
    color: #0F0;
}

.Inputfield label {
    color: #AAA;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

input,
select {
    width: 500px;
    height: 60px;
    background-color: inherit;
    border: inherit;
    border-bottom: 0.1px solid #AAA;
    color: #AAA;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0;
    transition: border-bottom 0.5s ease;
    resize: none;

}

input:focus,
select:focus {
    outline: none;
    border: none;
    border-bottom: 0.1px solid #0F0;
    transition: border-bottom 0.5s ease;
    resize: none;
}


.Button,
.ButtoN {
    width: 234px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid #0F0;
    background-color: inherit;
    color: #0F0;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    margin-top: 130px;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.ButtoN {
    background-color: inherit;
    margin-top: 0;
}

.ButtoN:hover {
    background-color: #0F0;
    color: #1A1A1A;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.Button:hover {
    background-color: #0F0;
    color: #1A1A1A;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.CloseButton {
    position: absolute;
    top: -15px;
    right: 25px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}



.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}


.Preview_Tab {
    width: 85%;
    height: 80%;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    background-color: #1A1A1A;
    border-radius: 40px;
    z-index: 999;

}

.Edit_Button {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding: 30px 30px 0px 0px;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
}

.Edit_Button p {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: auto;
    margin: 0;
}

.Icon {
    width: auto;
    height: auto;
    display: flex;
    cursor: pointer;
}

.List_1 {
    width: 100%;
    height: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.Lists {
    width: 85%;
    height: 100%;
    margin: auto;
    display: flex;
    gap: 50px;
}

.Lists h1,
.Lists label,
.Lists p {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: start;
    line-height: normal;
    /* overflow: scroll; */
}

.Preview_Text {
    width: auto;
    height: auto;
    margin: 0;
    display: grid;
    align-items: center;
    grid-template-columns: 0.65fr 0.25fr 0.65fr;

}

@keyframes slide {
    0% {
        margin-top: -1000px;
    }

    100% {
        margin-top: 0px;
    }
}

.Price_Section {
    width: auto;
    height: auto;
    margin: auto;
}

.Price_Section h1 {
    color: #0F0;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
}

.Button_Section {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 30px;
}


.InputField_box_1 {
    height: 60px;
    width: 500px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Open Sans";
    padding: 15px 0px;
    border: #010101;
    border-bottom: 0.1px solid #AAA;
    padding-bottom: 0;
    background-color: inherit;
    transition: border-bottom 0.5s ease;
    resize: none;
    display: flex;
    align-items: center;
}

.Desktop_Section {
    width: 100%;
    height: 100%;
    margin: auto;
}

.Mobile_Section {
    display: none;
}

@media (min-width: 768px) and (max-width: 1000px) {

    .Forms {
        height: auto;
    }

    .Form_Block h1 {
        font-size: 30px;
    }

    .Form_Block h2 {
        width: 85%;
        font-size: 18px;
    }


    .Inputfield h3 {
        font-size: 20px;
    }

    .Inputfield label {
        font-size: 20px;
    }

    .InputField_box input,
    select {
        width: 400px;
        height: 50px;
        padding: 10px 15px;
    }

    .InputField_box_1 {
        width: 430px;
    }

    .Edit_Button p {
        font-size: 16px;
    }

    .Edit_Button img {
        width: 20px;
        height: 20px;
    }

    .Lists h1,
    .Lists label,
    .Lists p {
        font-size: 16px;
    }

    .ButtoN {
        width: 200px;
        height: 40px;
        font-size: 18px;
    }
}


@media (min-width: 455px) and (max-width: 767px) {

    .Desktop_Section {
        display: none;
    }

    .text {
        width: 90%;
        font-size: 16px;
    }

    .Mobile_Section {
        display: block;
        width: 100%;
        height: 80%;
        margin: auto;
    }

    .Logo_Container img {
        width: 250px;
        height: 44px;
    }

    .Form_Block h1 {
        font-size: 25px;
    }

    .Form_Block h2 {
        width: 95%;
        font-size: 16px;
    }

    .Form_Enroll {
        margin-top: 50px;
    }

    .Forms {
        height: auto;
    }

    .Inputfield h6 {
        font-size: 10px;
        text-align: justify;
        width: 100%;
    }

    .Inputfield h3 {
        font-size: 14px;
        line-height: 25px;
    }

    .Inputfield label {
        font-size: 14px;
    }

    .InputField_box input,
    select {
        width: 250px;
        height: 50px;
        font-size: 16px;
    }

    .Button {
        width: 120px;
        height: 45px;
        font-size: 18px;


    }

    input,
    select {
        padding: 10px;
    }

    .Inputfield {
        align-items: center;

        grid-template-columns: 0.7fr 0.1fr 1fr;
    }


    .InputField_box_1 {
        width: 250px;
    }

    .custom-file-label {
        font-size: 14px !important;
    }

    .Edit_Button p {
        font-size: 16px;
    }

    .Edit_Button img {
        width: 20px;
        height: 20px;
    }

    .Lists {
        width: 85%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }

    .List_1 {
        height: 90%;

    }

    .Lists h1,
    .Lists label,
    .Lists p {
        font-size: 16px;
    }

    .Preview_Text {
        grid-template-columns: 1.25fr 0.5fr 0.5fr;
        margin-top: 20px;
    }

    .ButtoN {
        width: 200px;
        height: 40px;
        font-size: 18px;
    }

}

@media (min-width: 345px) and (max-width: 454px) {
    .Logo_Container img {
        width: 250px;
        height: 44px;
    }

    .Desktop_Section {
        display: none;
    }

    .text {
        width: 90%;
        font-size: 16px;
    }

    .Mobile_Section {
        display: block;
        width: 100%;
        height: 80%;
        margin: auto;
    }

    .Form_Block h1 {
        font-size: 20px;
    }

    .Form_Block h2 {
        width: 95%;
        font-size: 16px;
    }

    .Form_Enroll {
        margin-top: 50px;
    }

    .Forms {
        height: auto;
    }

    .Inputfield h3 {
        font-size: 16px;
        line-height: normal;
    }

    .Inputfield label {
        font-size: 16px;
    }

    .InputField_box input,
    select {
        width: 100%;
        height: 40px;
        font-size: 10px;
    }

    .Button {
        width: 100px;
        height: 30px;
        font-size: 12px;
        margin-top: 0px;

    }

    input,
    select {
        padding: 10px;
    }

    .Form_Enroll {
        gap: 70px;
    }

    .Inputfield {
        width: 90%;

        grid-template-columns: 0.6fr 0.1fr 1fr;
    }


    .InputField_box_1 {
        width: 111%;
    }

    .Inputfield h6 {
        font-size: 12px;
        width: 100%;
        text-align: left;
        margin-top: 2px;
    }

    .custom-file-label {
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .Edit_Button p {
        font-size: 16px;
    }

    .Edit_Button img {
        width: 20px;
        height: 20px;
    }

    .Lists {
        width: 85%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }

    .Lists h1,
    .Lists label,
    .Lists p {
        font-size: 14px;
    }

    .List_1 {
        height: auto;
    }

    .Preview_Text {
        grid-template-columns: 1.25fr 0.5fr 0.5fr;
        margin-top: 20px;
    }

    .ButtoN {
        width: 200px;
        height: 40px;
        font-size: 18px;
    }
}

@media (min-width: 100px) and (max-width: 344px) {

    .Logo_Container img {
        width: 250px;
        height: 44px;
    }

    .Desktop_Section {
        display: none;
    }

    .text {
        width: 90%;
        font-size: 16px;
    }

    .Mobile_Section {
        display: block;
        width: 100%;
        height: 80%;
        margin: auto;
    }

    .Form_Block h1 {
        font-size: 20px;
    }

    .Form_Block h2 {
        width: 95%;
        font-size: 16px;
    }


    .Form_Enroll {
        margin-top: 50px;
    }

    .Forms {
        height: auto;
    }

    .Inputfield h3 {
        font-size: 16px;
        line-height: normal;
    }

    .Inputfield label {
        font-size: 16px;
    }

    .InputField_box input,
    select {
        width: 100%;
        height: 40px;
        font-size: 10px;
    }

    .Button {
        width: 100px;
        height: 30px;
        font-size: 12px;
        margin-top: 0px;

    }

    input,
    select {
        padding: 10px;
    }

    .Inputfield {
        width: 100%;

        grid-template-columns: 0.6fr 0.1fr 1fr;
    }


    .InputField_box_1 {
        width: 100%;
    }

    .Inputfield h6 {
        font-size: 10px;
        width: 100%;
        text-align: left;
    }

    .custom-file-label {
        font-size: 12px !important;
        line-height: 20px !important;
    }

    .Edit_Button p {
        font-size: 16px;
    }

    .Edit_Button img {
        width: 20px;
        height: 20px;
    }

    .Lists {
        width: 85%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .List_1 {
        height: auto;
    }

    .Lists h1,
    .Lists label,
    .Lists p {
        font-size: 14px;
    }

    .Preview_Text {
        grid-template-columns: 1.25fr 0.5fr 0.5fr;
        margin-top: 20px;
    }

    .ButtoN {
        width: 200px;
        height: 40px;
        font-size: 18px;
    }


}