.Contact_Form {
  width: auto;
  height: auto;
  margin: auto;
  margin-top: 0;
}

.Contact_Form h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.Form_Container {
  width: auto;
  height: auto;
  margin: auto;
}

.Form_InputField_Container {
  width: auto;
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 0.2fr 0.1fr 0.2fr;
  margin-top: 90px;
}

.Form_InputField_Container label {
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Form_InputField_Container span {
  color: #0f0;
}

.Input,
.Input1,
.Input2,
.Input3 {
  width: 500px;
  height: 60px;
  background-color: #010101;
  border: #010101;
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0;
  transition: border-bottom 0.5s ease;
  resize: none;
}

.Input.Seen,
.Input1.Seen,
.Input2.Seen,
.Input3.Seen {
  border-bottom: 0.1px solid #aaa;
  background-position: 0;
  animation: borderAppear 5s ease forwards;
}

@keyframes borderAppear {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 0 50%;
  }

  to {
    background-position: 50% 100%;
  }
}

.Input.UnSeen,
.Input1.UnSeen,
.Input2.UnSeen,
.Input3.UnSeen {
  border-bottom: 0.1px solid #010101;
  transition: border-bottom 0.5s ease;
}

.Input:focus,
.Input1:focus,
.Input2:focus,
.Input3:focus {
  outline: none;
  border: none;
  border-bottom: 0.1px solid #0f0;
  transition: border-bottom 0.5s ease;
  resize: none;
}

.Form_InputField_Container textarea {
  height: 40px;
}

.Form_InputField_Container_A {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  padding-bottom: 120px;
}

.Form_InputField_Container_A button {
  width: 234px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 28px;
  border: 1px solid #0f0;
  background-color: #010101;
  color: #0f0;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: color ease 0.5s, background-color ease 0.5s;
}

.Form_InputField_Container_A button:hover {
  background-color: #0f0;
  color: #010101;
  transition: color ease 0.5s, background-color ease 0.5s;
}

.CloseButton {
  position: absolute;
  top: -15px;
  right: 25px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #aaa;
  z-index: 100;
}

.Popup {
  width: 430px;
  max-width: 90%;
  height: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #333;
  z-index: 200;
  animation: slide 0.5s;
}

@keyframes slide {
  0% {
    margin-top: -1000px;
  }

  100% {
    margin-top: 0px;
  }
}

.Popup span {
  display: inline-block;
}

.Popup img {
  width: auto;
  height: auto;
  margin: 8% auto;
  display: block;
  margin-bottom: 0;
  object-fit: contain;
}

.Popup h1 {
  color: white;
  font-family: "Open Sans";
  margin: auto;
  text-align: center;
}

.Popup h3 {
  width: 80%;
  height: auto;
  margin: auto;
  margin-top: -24px;
  font-family: "Open Sans";
  color: white;
  font-weight: 400;
  margin: auto;
  text-align: center;
}

@media screen and (min-width: 510px) and (max-width: 1080px) {
  .Section_7 {
    padding-top: 60px;
    width: 100%;
  }

  .Contact_Form {
    margin-top: 60px;
    width: 90%;
  }

  .Contact_Form h1 {
    font-size: 26px;
  }

  .Form_InputField_Container {
    grid-template-columns: 0.5fr 0.2fr 0.1fr;
    margin-top: 60px;
  }

  .Form_InputField_Container label {
    font-size: 18px;
  }

  .Input,
  .Input1,
  .Input2,
  .Input3 {
    width: 200px;
    height: 30px;
    font-size: 12px;
  }

  .Form_InputField_Container textarea {
    height: 20px;
  }

  .Form_InputField_Container_A {
    margin-top: 60px;
    padding-bottom: 100px;
  }

  .Form_InputField_Container_A button {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }

  .Popup h1 {
    font-size: 28px;
    margin: auto;
  }

  .Popup h3 {
    font-size: 18px;
  }

  .CloseButton {
    top: -15px;
  }
}

@media screen and (min-width: 260px) and (max-width: 509px) {
  .Section_7 {
    width: 90%;
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }

  .Contact_Form {
    width: 100%;
    margin-top: 30px;
  }

  .Contact_Form h1 {
    font-size: 19px;
    text-align: center;
  }

  .Form_Container {
    display: block;
    justify-content: center;
  }

  .Form_InputField_Container {
    grid-template-columns: 35% 3% 62%;
    margin-top: 30px;
  }

  .Form_InputField_Container label {
    font-size: 14px;
  }

  .Input,
  .Input1,
  .Input2,
  .Input3 {
    width: 90%;
    height: 25px;
    font-size: 14px;
  }

  .Form_InputField_Container textarea {
    height: 20px;
  }

  .Form_InputField_Container_A {
    margin-top: 60px;
    padding-bottom: 100px;
  }

  .Form_InputField_Container_A button {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }

  .Popup h1 {
    font-size: 28px;
    margin: auto;
  }

  .Popup h3 {
    font-size: 18px;
  }

  .CloseButton {
    top: -15px;
  }
}