.firstsec_cont,
.secondsec_cont {
  width: auto;
  height: auto;
  max-width: 1920px;
  margin: auto;
  width: 100%;
}


.Image_Container {
  width: auto;
  height: auto;
  margin: auto;
  position: relative;

}

.Image_Container svg {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 60%;
}

@keyframes fill-bar {
  0% {
    fill: transparent;
  }

  100% {
    fill: #0F0;
  }
}


.wifi-symbol path:not(:first-child) {
  fill: transparent;
  animation: fill-bar 1s infinite;

}

.wifi-symbol path:nth-child(1) {
  animation-delay: 0s;
}

.wifi-symbol path:nth-child(2) {
  animation-delay: 0.3s;
}

.wifi-symbol path:nth-child(3) {
  animation-delay: 0.9s;
}

.wifi-symbol path:nth-child(4) {
  animation-delay: 0.6s;
}


.firstsec,
.Secondsec {
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}


.section_paras {
  margin-top: 120px;
}

.firstsec img {
  width: 500px;
  height: 300px;
  margin: auto;
  margin-top: 200px;
}

.section_paras h1,
.section_paras_2 h1,
.section_paras_3 h1,
.section_questions h1,
.section_paras_4 h1 {
  color: #0F0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  /* width: 100%; */
}

.section_paras p,
.section_paras_2 p,
.section_paras_3 p,
.section_paras_4 p {
  color: #AAA;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  margin: auto;
}

.section_paras_2,
.section_paras_3,
.section_paras_4 {
  margin-top: 120px;
}

.section_paras span,
.section_paras_2 span,
.section_paras_3 span,
.section_paras_4 span {
  color: #0F0;
}


.section_questions {
  margin-top: 120px;
  width: 100%;
  /* margin: auto; */
}

.section_questions p {
  color: #AAA;
  text-align: justify;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.44px;
  width: 95%;
}

.line {
  width: 100%;
  height: 0.5px;
  background-color: #0F0;
  animation: glow 2.5s infinite alternate;
  color: #0F0;
}

@keyframes glow {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.Points {
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: 0.03fr 1fr;
}



@media screen and (min-width:300px) and (max-width:600px) {

  .firstsec,
  .Secondsec {
    width: 100%;
    margin: auto;
  }

  .firstsec img {
    width: 300px;
    margin-top: 90px;
  }

  .Image_Container svg {
    width: 50px;
    height: 50px;
    top: 40%;
    left: 63%;
  }

  .section_paras {
    margin-top: 0px;
  }

  .section_paras,
  .section_paras_2,
  .section_paras_3,
  .section_questions,
  .section_paras_4 {
    margin-top: 40px;
    width: 100%;
  }


  .section_paras h1,
  .section_paras_2 h1,
  .section_paras_3 h1,
  .section_questions h1,
  .section_paras_4 h1 {
    font-size: 30px;
    width: 100%;
  }

  .section_paras p,
  .section_paras_2 p,
  .section_paras_3 p,
  .section_paras_4 p {
    /* .section_questions p { */
    font-size: 14px;
    line-height: normal;
    text-align: justify;
  }

  .section_questions p {
    width: 100%;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: normal;
    text-align: justify;
  }

  .secondsec_cont {
    width: 100%;
    margin: auto;
  }

  .Points {
    width: 100%;
    margin: auto;
  }

}