.FEE_Section {
    width: 85%;
    height: auto;
    margin: auto;
    max-width: 1920px;
}

/* .FEE_Section p {
    font-size: 14px;
} */

.How_It_Works {
    width: auto;
    height: auto;
    padding-top: 170px;
}

.HIW_Heading h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.HIW_Image,
.HIW_Image_Mobile {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;

}

.HIW_Image_Mobile {
    display: none;
}

.HIW_Image img {
    width: auto;
    height: auto;
    margin-top: 120px;
}

.How_It_Works p {
    padding-top: 120px;
    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.44px;
    margin-top: 60px;
}

/* .Details_Of_Program {
    width: auto;
    height: auto;
} */

.DOP_Section {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
}

.DOP_Section h6 {
    text-align: left;
}

.Details_Of_Program {
    width: min(72.9vw, 1400px);
    height: 880px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #4B4B4B;
    background: #121212;
}

.Details_Of_Program h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
}

.Details {
    width: 90%;
    height: auto;
    margin: auto;
}

.Point {
    width: 100%;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
}

.Point p {
    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
    align-items: normal;
    margin-left: 0px;
    margin-right: 0px;
}

.RefundP {
    width: 85%;
    height: auto;
    margin: auto;
}

.L {
    width: 100%;
    height: 0.5px;
    background-color: #0F0;
    animation: glow 2.5s infinite alternate;
    color: #0F0;
}

@keyframes glow {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.FAQ_Section {
    width: auto;
    height: auto;
    margin-top: 170px;
}

.FAQ_Section h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (min-width:1301px) and (max-width:1530px) {
    .HIW_Image img {
        width: 1050px;
        height: auto;
    }
}

@media screen and (min-width:1051px) and (max-width:1300px) {
    .HIW_Heading h1 {
        font-size: 40px;
    }

    .How_It_Works p {
        font-size: 20px;

    }

    .HIW_Image img {
        width: 900px;
        height: auto;
    }

    .Details_Of_Program h1 {
        font-size: 40px;
    }

    .Point p {
        font-size: 20px;
    }

    .FAQ_Section h1 {
        font-size: 40px;
    }

    .FEE_Section {
        width: 90%;
    }
}

@media screen and (min-width:831px) and (max-width:1050px) {
    .HIW_Heading h1 {
        font-size: 35px;
    }

    .How_It_Works p {
        font-size: 18px;

    }

    .HIW_Image {
        display: none;
    }

    .HIW_Image_Mobile {
        display: block;
        width: auto;
        height: auto;
        display: flex;
        margin-top: 120px;
    }

    .Details_Of_Program {
        height: auto;
        padding-bottom: 30px;
    }

    .Details_Of_Program h1 {
        font-size: 35px;
    }

    .Details {
        margin: 0;
    }

    .Point p {
        font-size: 16px;
    }

    .FAQ_Section h1 {
        font-size: 35px;
    }

    .FEE_Section {
        width: 90%;
    }
}

@media screen and (min-width:601px) and (max-width:830px) {
    .HIW_Heading h1 {
        font-size: 30px;
    }

    .How_It_Works p {
        font-size: 16px;
    }

    .HIW_Image {
        display: none;
    }

    .HIW_Image_Mobile {
        display: block;
        width: auto;
        height: auto;
        display: flex;
        margin-top: 120px;
    }

    .HIW_Image_Mobile img {
        width: 550px;
        height: auto;
    }

    .Details_Of_Program {
        height: auto;
        padding-bottom: 30px;
    }

    .Details_Of_Program h1 {
        font-size: 25px;
    }

    .Details {
        margin: 0;
    }

    .Point p {
        font-size: 12px;
    }

    .FAQ_Section h1 {
        font-size: 30px;
    }

    .FEE_Section {
        width: 90%;
    }
}

@media screen and (min-width:481px) and (max-width:600px) {
    .FEE_Section {
        width: 100%;
        margin: auto;
    }

    .How_It_Works {
        padding-top: 120px;
    }

    .HIW_Heading h1 {
        font-size: 25px;
    }

    .How_It_Works p {
        font-size: 14px;
        line-height: 24px;

    }

    .HIW_Image {
        display: none;
    }

    .HIW_Image_Mobile {
        display: block;
        width: auto;
        height: auto;
        display: flex;
        margin-top: 60px;
    }

    .HIW_Image_Mobile img {
        width: 450px;
        height: auto;
    }

    .Details_Of_Program {
        width: 450px;
        height: auto;
        padding-bottom: 30px;
    }

    .Details_Of_Program h1 {
        font-size: 20px;
    }


    .Details {
        margin: 0;
    }

    .Point p {
        font-size: 10px;
    }

    .FAQ_Section h1 {
        font-size: 25px;
    }
}

@media screen and (min-width:381px) and (max-width:480px) {
    .FEE_Section {
        width: 90%;
        margin: auto;
    }

    .How_It_Works {
        padding-top: 120px;
    }

    .HIW_Heading h1 {
        font-size: 30px;
    }

    .How_It_Works p {
        font-size: 14px;
        line-height: 24px;
        margin-top: -50px;

    }

    .HIW_Image {
        display: none;
    }

    .HIW_Image_Mobile {
        display: block;
        width: auto;
        height: auto;
        display: flex;
        margin-top: 60px;
    }

    .HIW_Image_Mobile img {
        width: 350px;
        height: auto;
    }

    .Details_Of_Program {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
    }

    .Details_Of_Program h1 {
        font-size: 20px;
    }

    .Details {
        margin: 0;
    }

    .Point {
        grid-template-columns: 1fr 1fr;
    }


    .Point p {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        text-align: start;
    }

    .FAQ_Section {
        margin-top: 70px;
    }

    .FAQ_Section h1 {
        font-size: 30px;
    }
}

@media screen and (min-width:260px) and (max-width:380px) {
    .HIW_Heading h1 {
        font-size: 30px;
    }

    .How_It_Works p {
        font-size: 14px;
        line-height: 24px;
        margin-top: -50px;

    }

    .HIW_Image {
        display: none;
    }

    .HIW_Image_Mobile {
        display: block;
        width: auto;
        height: auto;
        display: flex;
        margin-top: 60px;
    }

    .HIW_Image_Mobile img {
        width: 220px;
        height: auto;
    }


    .Details_Of_Program {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
    }

    .Details_Of_Program h1 {
        font-size: 16px;
    }

    .Details {
        margin: 0;
    }

    .Point {
        grid-template-columns: 0.8fr 1fr;
    }

    .Point p {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        text-align: start;
    }

    .FAQ_Section {
        margin-top: 70px;
    }

    .FAQ_Section h1 {
        font-size: 30px;
    }

    .FEE_Section {
        width: 90%;
    }

    .How_It_Works {
        margin: auto;
        width: 100%;
    }
}