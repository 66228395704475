.SVG_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}

.UP p {
  width: 135%;
  color: #AAA;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: -130%;
  right: -30%;
  margin: 0;
  text-align: center;
}

.DOWN p {
  width: 122%;
  color: #AAA;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  right: -18%;
  margin: 0;
  bottom: -18%;
  text-align: center;
}


.path-animation-UP,
.path-animation-UP1,
.path-animation-UP2,
.path-animation-UP3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 620;
  animation: dash1 0.5s ease-in forwards;
}

.path-animation-UP1 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 1s;
  stroke: #010101;
}

.path-animation-UP2 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 2s;
  stroke: #010101;
}

.path-animation-UP3 {
  animation: dash1 0.5s ease-in forwards, changeStrokeColor 0.5s forwards;
  animation-delay: 3s;
  stroke: #010101;

}

@keyframes changeStrokeColor {
  to {
    stroke: #AAA;

  }
}

@keyframes dash1 {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 620;
  }
}



.path-animation-DOWN,
.path-animation-DOWN1,
.path-animation-DOWN2 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash2 0.5s ease-in forwards;
}

@keyframes dash2 {
  to {
    stroke-dashoffset: 620;

  }
}

.path-animation-DOWN {
  animation-delay: 0.5s;
}

.path-animation-DOWN1 {
  animation-delay: 1.5s;
}

.path-animation-DOWN2 {
  animation-delay: 2.5s;
}

.PATHUP {
  transform: scaleX(-1) rotate(90deg);
  margin-left: -48px;

}


.PATHDOWN {
  margin-top: 240px;
  transform: rotate(-90deg);
  margin-left: -47px;

}

.UP,
.DOWN {
  width: auto;
  height: auto;
  position: relative;
}

.UP img,
.DOWN img {
  width: auto;
  height: auto;
  position: absolute;
  right: 7%;
  opacity: 0;

}

.UP img {
  top: -2%;
  right: 24%;
}

.DOWN img {
  top: 28.4%;
  right: 24%;
}



@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.Frst {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 0.5s;
}

.Sec {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 1s;
}

.Tri {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 1.5s;
}

.For {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 2s;
}

.Fiv {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 2.5s;
}

.Six {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 3s;
}

.Sev {
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 3.5s;
}

@media screen and (min-width:1050px) and (max-width:1240px) {

  .PATHUP,
  .PATHDOWN {
    width: 170px;
    height: 150px;
  }

  .PATHUP {
    transform: scaleX(-1) rotate(90deg);
    margin-left: -40px;

  }


  .PATHDOWN {
    margin-top: 200px;
    transform: rotate(-90deg);
    margin-left: -40px;

  }

  .UP img,
  .DOWN img {
    width: 47px;
    height: 42px;

  }

  .UP img {
    top: -9.5%;
    left: 43%;
  }

  .DOWN img {
    top: 23.5%;
    left: 43%;
  }

  .UP p,
  .DOWN p {
    font-size: 20px;
  }

}