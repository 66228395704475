.OurPrograms {
    width: 85%;
    height: auto;
    margin: auto;
    padding-top: 200px;
    overflow: hidden;
    max-width: 1920px;
}

.OurPrograms h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.OurPrograms h2 {

    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Program_Container {
    width: 85%;
    height: auto;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 100px;
    column-gap: 350px;
    margin-top: 100px;
}

.Program_Container a {
    text-decoration: none;
}

.BOX_Container h1 {
    color: #0F0;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (min-width:260px) and (max-width:460px) {

    .OurPrograms {
        width: 90%;
        padding-top: 120px;
    }

    .OurPrograms h1 {
        font-size: 30px;
    }

    .OurPrograms h2 {
        font-size: 14px;
    }
}