.Section-Ai {
    font-family: "Open Sans";
    margin: auto;
}

.background2-container {
    height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom,
            #010101 0%,
            #010101 50%,
            #1A1A1A 50%,
            #1A1A1A 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.inner-container {
    margin: auto;
    width: 85%;
    height: auto;
    max-width: 1920px;
    position: relative;
    text-align: center;
}

.inner-container h1 {
    margin-top: 0;
    color: #00FF00;
    /* height: auto; */
    font-size: 96px;
    margin-bottom: 22px;
    font-weight: 900;
}

.inner-container img {
    width: auto;
    margin: auto;
    height: auto;
}

.inner-container h3 {
    color: #AAAAAA;
    font-size: 50px;
    font-weight: 600;
}

.inner-container p {
    width: 70%;
    margin: auto;
    font-size: 20px;
    font-weight: 400;
    color: #AAAAAA;
}

.text-containerai {
    width: 85%;
    max-width: 1920px;
    margin: auto;
    color: #AAAAAA;
    padding: 96px 0;

}

.text-containerai h2 {
    color: #00FF00;
    font-size: 50px;
}

.background {
    background-color: #1A1A1A;
}

.AiMl_Developement {
    color: #AAAAAA;
    width: 85%;
    max-width: 1920px;
    margin: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 96px 0;
    /* gap: 40px; */
}

.AiMl_Image img {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 35px;
}

.AiMl_Image {
    align-items: center;
    display: flex;
}

.Text_Holder_AiMl {
    width: 60%;
}

.Text_Holder_AiMl h3 {
    color: #00FF00;
    margin: 0;
    font-size: 32px;
}

.Text_Holder_AiMl h1,
.Text_Holder_AiMl2 h1 {
    font-size: 50px;
}

.Text_Holder_AiMl p,
.Text_Holder_AiMl2 p,
.text-containerai p {
    font-size: 20px;
    text-align: justify;
    line-height: 1.4;
}

.Text_Holder_AiMl2 {
    width: 85%;
    max-width: 1920px;
    margin: auto;
    height: auto;
    color: #AAAAAA;
}

.Apply-AiMl {
    width: auto;
    height: 48px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 2px solid #0f0;
    /* background-color: #0F0; */
    color: #0f0;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    padding: 8px 30px;
    font-weight: 900 !important;
    line-height: normal;
    cursor: pointer;
    transition: none;
}

.Apply-AiMl:hover {
    background-color: #0f0;
    color: #010101;
}

.AiMl-tools {
    width: 85%;
    max-width: 1920px;
    color: #AAAAAA;
    margin: auto;
    height: auto;
    padding: 96px 0;
}

.AiMl-tools h1,
.AiMl-projects h1,
.intern-works h1 {
    color: #00FF00;
    font-size: 50px;
}

.AiMl-tools-container {
    display: flex;
    flex-wrap: wrap;
    gap: 11%;

}

.box p {
    font-size: 20px;
    font-weight: bold;
}

.box {
    margin-top: 20px;
}

.AiMl-projects {
    width: 85%;
    max-width: 1920px;
    height: auto;
    margin: auto;
    color: #AAAAAA;
    padding: 96px 0;
}

.project-holder {
    display: flex;
    flex-wrap: wrap;
    gap: 111px;
    padding: 26px 0;
}

.project-card {
    width: 281px;
    height: 180px;
    background-color: #010101;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 12px;
}

.project-card img {
    margin-top: 16px;
    filter: grayscale(1);
}

.project-card p {
    font-size: 20px;
}

.paragraph {
    width: auto;
    display: flex;
    align-items: center;
    margin: auto;
    height: auto;
    text-align: center;
}

.intern-works {
    width: 85%;
    max-width: 1920px;
    margin: auto;
    height: auto;
    padding: 96px 0;
}

@media screen and (min-width:1440px) and (max-width:1680px) {
    .inner-container {
        width: 85%;
    }

    .inner-container h1 {
        font-size: 84px;
        margin-top: 45px;
    }

    .inner-container img {
        width: 68%;
    }

    .inner-container h3 {
        font-size: 46px;
    }

    .inner-container p {
        width: 85%;
        margin: auto;
        font-size: 20px;
    }

    .project-holder {
        gap: 68px;
    }

    .text-containerai h2 {
        font-size: 46px;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 46px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 30px;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        font-size: 46px;
    }

    .AiMl-tools-container {
        gap: 9%;
    }
}

@media screen and (min-width:1280px) and (max-width:1439px) {
    .background2-container {
        padding: 35px 0;
    }

    .inner-container {
        width: 85%;
    }

    .inner-container h1 {
        font-size: 68px;
        margin-top: 45px;
        font-weight: 800;
    }

    .inner-container img {
        width: 60%;
    }

    .inner-container h3 {
        font-size: 38px;
    }

    .inner-container p {
        width: 85%;
        margin: auto;
        font-size: 20px;
    }

    .text-containerai h2 {
        font-size: 44px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 18px;

    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 44px;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        font-size: 44px;
    }

    .AiMl-tools-container {
        gap: 14%;
    }

    .project-holder {
        justify-content: center;
        gap: 56px;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .background2-container {
        padding: 10px 0;
    }

    .inner-container {
        width: 85%;
    }

    .inner-container h1 {
        font-size: 48px;
        margin-top: 26px;
        font-weight: 800;
    }

    .inner-container img {
        width: 60%;
    }

    .inner-container h3 {
        font-size: 30px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 85%;
        margin: auto;
        font-size: 18px;
    }

    .text-containerai h2 {
        font-size: 44px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 18px;

    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 44px;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        font-size: 44px;
    }

    .AiMl-tools-container {
        gap: 14%;
    }

    .project-holder {
        justify-content: center;
        gap: 56px;
    }
}

@media screen and (min-width:769px) and (max-width:1023px) {
    .background2-container {
        padding: 10px 0;
    }

    .inner-container {
        width: 85%;
    }

    .inner-container h1 {
        font-size: 48px;
        margin-top: 52px;
        font-weight: 800;
    }

    .inner-container img {
        width: 60%;
    }

    .inner-container h3 {
        font-size: 30px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 85%;
        margin: auto;
        font-size: 16px;
    }

    .AiMl_Developement {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .text-containerai {
        width: 90%;
        font-size: 14px;
    }

    .text-containerai h2 {
        font-size: 26px;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 26px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 26px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        /* justify-content: center; */
        gap: 17%;
    }

    .project-holder {
        justify-content: center;
        gap: 32px;
    }

    .project-card {
        height: 186px;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }
}

@media screen and (min-width:600px) and (max-width:768px) {
    .inner-container {
        width: 90%;
    }

    .inner-container h1 {
        font-size: 40px;
        margin-top: 20px;
        font-weight: 800;
    }

    .inner-container img {
        width: 68%;
    }

    .inner-container h3 {
        font-size: 24px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 85%;
        margin: auto;
        font-size: 14px;
    }

    .AiMl_Developement {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .text-containerai {
        width: 90%;
        font-size: 14px;
    }

    .text-containerai h2 {
        font-size: 30px;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 24px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 24px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        /* justify-content: center; */
        gap: 20%;
    }

    .project-holder {
        justify-content: center;
        gap: 32px;
    }

    .project-card {
        height: 186px;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }
}

@media screen and (min-width:480px) and (max-width:599px) {
    .inner-container {
        width: 90%;
    }

    .inner-container h1 {
        font-size: 34px;
        margin-top: 36px;
        font-weight: 800;
    }

    .inner-container img {
        width: 76%;
    }

    .inner-container h3 {
        font-size: 22px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 100%;
        margin: auto;
        font-size: 14px;
    }

    .text-containerai {
        width: 90%;
        font-size: 14px;
    }

    .text-containerai h2 {
        font-size: 30px;
    }

    .AiMl_Developement {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 24px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 24px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        justify-content: center;
        gap: 20%;
    }

    .project-holder {
        justify-content: center;
        gap: 40px;
    }

    .project-card {
        height: auto;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }
}

@media screen and (min-width:425px) and (max-width:479px) {
    .inner-container {
        width: 90%;
    }

    .inner-container h1 {
        font-size: 34px;
        margin-top: 48px;
        font-weight: 800;
    }

    .inner-container img {
        width: 76%;
    }

    .inner-container h3 {
        font-size: 22px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 100%;
        margin: auto;
        font-size: 14px;
    }

    .text-containerai {
        width: 90%;
        font-size: 14px;
    }

    .text-containerai h2 {
        font-size: 30px;
    }

    .AiMl_Developement {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .AiMl_Image img {
        margin: auto;
        margin-top: 80px;
        margin-bottom: 80px;
        width: 64%;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 24px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 24px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        justify-content: center;
        gap: 20%;
    }

    .project-holder {
        justify-content: center;
        gap: 40px;
    }

    .project-card {
        height: auto;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }
}

@media screen and (min-width:362px) and (max-width:424px) {
    .inner-container {
        width: 90%;
    }

    .inner-container h1 {
        font-size: 40px;
        margin-top: 30px;
        font-weight: 800;
    }

    .inner-container img {
        width: 90%;
    }

    .inner-container h3 {
        font-size: 18px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 100%;
        margin: auto;
        font-size: 14px;
    }

    .text-containerai {
        width: 90%;
        /* font-size: 14px; */
        /* line-height: normal; */
        padding: 40px 0;
    }

    /* .text-containerai p {
        font-size: 14px;
        line-height: 1.4;
    } */

    .text-containerai h2 {
        font-size: 24px;
    }


    .AiMl_Developement {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 40px 0;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .AiMl_Image img {
        margin: auto;
        margin-top: 80px;
        margin-bottom: 80px;
        width: 64%;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 24px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 24px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        justify-content: center;
        gap: 20%;
    }

    .project-holder {
        justify-content: center;
        gap: 40px;
    }

    .project-card {
        height: auto;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }

}

@media screen and (min-width:320px) and (max-width:361px) {
    .inner-container {
        width: 90%;
    }

    .inner-container h1 {
        font-size: 32px;
        margin-top: 46px;
        font-weight: 800;
    }

    .inner-container img {
        width: 90%;
    }

    .inner-container h3 {
        font-size: 17px;
        margin-top: 19px;
    }

    .inner-container p {
        width: 100%;
        margin: auto;
        font-size: 12px;
    }

    .text-containerai {
        font-size: 14px;
        line-height: normal;
        width: 90%;
    }

    .text-containerai h2 {
        font-size: 30px;
    }

    .AiMl_Developement {
        display: flex;
        flex-direction: column;
    }

    .AiMl_Image {
        align-items: center;
        display: flex;
        width: auto;
        height: auto;
        margin: auto;
    }

    .AiMl_Image img {
        margin: auto;
        margin-top: 54px;
    }

    .Text_Holder_AiMl {
        width: auto;
    }

    .Text_Holder_AiMl h1,
    .Text_Holder_AiMl2 h1 {
        font-size: 24px;
    }

    .Text_Holder_AiMl h3 {
        font-size: 18px;
    }

    .Text_Holder_AiMl p,
    .Text_Holder_AiMl2 p,
    .text-containerai p {
        font-size: 16px;
        line-height: 1.4;
    }

    .AiMl-projects {
        padding: 40px 0;
        width: 90%;
    }

    .Apply-AiMl {
        /* width: 38px; */
        height: 44px;
        font-size: 16px;
    }

    .Text_Holder_AiMl2 {
        width: 90%;
    }

    .AiMl-tools h1,
    .AiMl-projects h1,
    .intern-works h1 {
        color: #00FF00;
        font-size: 24px;
    }

    .AiMl-tools {
        width: 90%;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 80px;
    }

    .AiMl-tools-container {
        justify-content: center;
        gap: 20%;
    }

    .project-holder {
        justify-content: center;
        gap: 40px;
    }

    .project-card {
        height: auto;
        padding: 0 24px;
    }

    .project-card p {
        font-size: 17px;
        margin: 24px 0;
    }

    .project-card img {
        width: 48x;
        margin-top: 24px;
    }

    .intern-works {
        width: 90%;
    }
}