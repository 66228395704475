@font-face {
    font-family: "Open Sans";
    src: url('../src/assets/OpenSans-Regular.woff2') format('woff2'),
        url('../src/assets/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url('../src/assets/Inter-Regular.woff2') format('woff2'),
        url('../src/assets/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}