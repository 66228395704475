input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

body {
    background-color: #010101;
}

.Main1 {
    max-width: 1920px;
    margin: auto;
    height: auto;
    background-color: #010101;
    font-family: sans-serif;
    color: #AAAAAA;

}

.Image_containerm {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.Headerm {
    width: 100%;
    height: min(90px, 9.22vh);
    background-color: #010101;
    display: flex;
    top: 0px;
    position: fixed;
    margin-left: 60px;
    align-items: center;
    box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 101;
    cursor: default;
    transition: top 0.3s ease;
}

/* .video_container iframe {
    height: 70vh;
    width: 70%;
    margin: auto;
    position: relative;
    display: block;
    overflow: hidden;
    color: white;
    margin-top: 140px;
} */

.Section_1 {
    width: 85%;
    height: 100vh;
    margin: auto;
    display: flex;

}

.Section_Contents {
    width: auto;
    height: auto;
    margin: auto;
}

.Mid_Text {
    width: 1162px;
    height: auto;
    margin: auto;
    display: grid;
}

.Mid_Text h1 {
    margin: 0;
    font-family: "Open Sans";
    font-size: 147px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.Mid_Text h2 {
    margin: 0;
    margin-top: 20px;
    color: #aaa;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Mid_Text h3 {
    color: #aaa;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.SVG_Container {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: min(13.3vh, 130px);
}

.ScrollButton,
.Button_Left,
.Button_Right {
    width: auto;
    height: auto;
}

.ScrollButton:hover,
.Button_Left:hover,
.Button_Right:hover {
    cursor: pointer;
}

.Arrow_Down {
    fill: white;
    transform: translateY(0);
    transition: transform 1s;
    animation: colorChange 1s infinite;
}

@keyframes colorChange {
    0% {
        fill: white;
        transform: translateY(0);
    }

    50% {
        fill: #0f0;
        transform: translateY(5px);
    }

    100% {
        fill: white;
        transform: translateY(0);
    }
}

.ScrollButton:hover .Arrow_Down,
.Button_Right:hover .Arrow_Right,
.Button_Left:hover .Arrow_Left {
    fill: #0f0;
}

.Button_Right:hover .Arrow_Right {
    transform: translateX(5px);
    transition: transform 0.5s ease;
}

.Button_Left:hover .Arrow_Left {
    transition: transform 0.5s ease;
    transform: translateX(-5px);
}

.ScrollButton:hover .Circle,
.Button_Left:hover .Circle,
.Button_Right:hover .Circle {
    stroke: #0f0;
    stroke-width: 3;
}

.sample {
    color: #010101;

}

.enroll {
    margin-top: 25px;
}

.enroll p {
    color: #989898;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    margin: 0px;
    margin: auto;
    width: 100%;
}

.enroll h3 {
    color: #00FF00;
    font-size: 54px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
}

.content {
    width: 85%;
    margin: auto;
    text-align: justify;
    justify-content: center;
    line-height: 1.5;
    margin-top: 30px;
    font-size: 20px;

}

.form-container-mc {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 150px;
    background: #1E1E1E;
    border-radius: 10px;
}

.form-mc {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    color: #AAAAAA;
    background: #1E1E1E;
    padding: 60px 0px;

}

/* .form {
    margin-top: 20px;
    padding: 10px;
} */

.form-input-mc {
    background: #1E1E1E;
}

.Inputfield-mc {
    width: auto;
    height: auto;
    background: #1E1E1E;
    display: grid;
    align-items: center;
    grid-template-columns: 0.5fr 0.4fr 0.8fr;
}

.Inputfield-mc h3 {
    color: #AAA;
    /* font-family: "Open Sans"; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    background: #1E1E1E;
    /* 150% */
}

.Inputfield-mc span {
    color: #0F0;
    background: #1E1E1E;
}

.Inputfield-mc label {
    color: #AAA;
    /* font-family: "Open Sans"; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    background: #1E1E1E;
    /* 150% */
}

/* .form-input {
    background: none;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    /* width: 300px; */
/* height: 35px; */
/* color: #AAAAAA; */
.InputField_box-mc {
    background: #1E1E1E;
}

.InputField_box_email-mc {
    position: relative;
    width: auto;
    height: auto;
    background: #1E1E1E;
    /* margin-top: 10px; */
    /* grid-template-columns: 0.5fr 0.4fr 0.8fr; */
}

.InputField_box_email-mc label {
    /* width: 100%; */
    color: #AAA;
    /* font-family: "Open Sans"; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    background: #1E1E1E;
}

.InputField_box_email-mc input {
    padding-right: 20px;
}

.inside-button-mc {
    position: absolute;
    width: auto;
    right: 0;
    top: 0;
    height: 34px;
    padding-right: 15px;
    border: none;
    margin-top: 20px;
    margin-right: 1px;
    background: #1E1E1E;
    color: #00FF00;
    font-weight: 300;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

}

/* .inside-button:hover {
    background-color: #00FF00;
} */

.containter1_otp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px;
    background-color: #1E1E1E;
    font-size: 12px;
}

.containter1_otp p {
    background-color: #1E1E1E;
}

#otpContainer-mc {
    margin-top: 10px;
    width: 100%;
    background: #1E1E1E;
}

.otp-field-container-mc {
    width: 40%;
    margin: auto;
    margin-left: 55%;
    /* MARGIN-TOP: 10px; */
    background: #1E1E1E;
}

.otp-inputs-mc {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
    gap: 7px;
    background: #1E1E1E;
}

.otp-field-mc {
    border-radius: 10px;
    /* width: 4px; */
    background: #1E1E1E;
    border: 1px solid #AAAAAA;
    color: #AAAAAA;
    padding: 7px;

}

.otp-field-mc::marker {
    border: 1px solid #0F0;
    background: #1E1E1E;
}

.otp-inputs-mc:focus {
    border: 1px solid #0F0;
}

.warning {
    background-color: #1E1E1E;
}

.warning p {
    text-align: end;
}

input,
button {
    margin-top: 10px;
    padding: 0 12px;
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    background: #1E1E1E;
}



.Selectbox-mc,
.Selectbox-mc:hover {
    background: #1E1E1E;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    width: 100%;
    height: 55px;
    border-radius: 8px;
    color: #AAAAAA;
    padding: 3px 8px;
}

.form-input-mc:focus,
.Selectbox-mc:focus,
.otp-field-mc:focus {
    /* outline: none; */
    border: 1px solid #AAAAAA;
    border: 0.1px solid #0F0;
    transition: border-bottom 0.5s ease;
    resize: none;
    background: #1E1E1E;
}

textarea {
    background-color: #1E1E1E;
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    color: #AAAAAA;
    font-size: 22px;
    width: 100%;
}

.TextArea_box-mc textarea:focus {
    border: 1px solid #0F0 !important;
}

.TextArea_box-mc {
    background-color: #1E1E1E;
}


.Suggestion_area-mc h3 {
    font-family: sans-serif;
    font-size: 22px;
    font-weight: lighter;
    background-color: #1E1E1E;
}

.Suggestion_area-mc {
    background-color: #1E1E1E;
}

.Suggestion_area-mc span {
    background-color: #1E1E1E;
}

.buttonS-mc {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    background: #1E1E1E;
}

/* .buttonS button {
    border: 1px solid #00FF00;
    color: #00FF00;
    font-weight: 100;
    border-radius: 20px;
    padding: 8px 7px;
    width: 20%;
    background: transparent;
} */
.Button-mc {
    width: 234px;
    height: 56px;
    color: #0f0;
    font-size: 24px;
    transition: color 0.5s ease, background-color 0.5s ease;
    font-family: "Open Sans";
    background-color: #1E1E1E;
    border-radius: 28px;
    border: 1px solid #0f0;
    font-weight: 700;
    cursor: pointer;
}

.Button-mc:hover {
    background-color: #0f0;
    color: #1E1E1E;
    transition: color 0.5s ease, background-color 0.5s ease;
}

.CloseButton {
    position: absolute;
    top: -15px;
    right: 25px;
    cursor: pointer;
    width: 20px;
    height: 20px;
   
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.778);
    z-index: 100;
}

.Popup {
    width: 430px;
    max-width: 90%;
    height: 320px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: #333;
    z-index: 200;
    animation: slide 0.5s;
}

.Popup h1 {
    margin-top: 20px;
    font-size: 28px;
    padding: 10px;
    margin: auto;
    color: white;
}

.Popup h3 {
    width: 80%;
    height: auto;
    margin: auto;
   margin-top: -24px;
    font-family: "Open Sans";
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.Popup span {
    display: inline-block;
    margin-top: 15px;
}

.close button {
    width: 30px;
    height: 30px;
    background-color: #0F0;
    color: black;
}

.Suggestion_area-mc span {
    color: #0F0;
}

/* .buttonS button:focus {
    background: #00FF00;
    color: #1E1E1E;
} */

/* @media (min-width: 100px) and (max-width: 344px) {
    form {
        width: 90%;
        margin-top: 7px;
    }

    .Inputfield-mc h3 {
        font-size: 11px;
    }

    .Inputfield-mc label {
        font-size: 11px;
    }

    .InputField_box-mc input,
    .Selectbox-mc {
        width: 110px;
        height: 30px;
        font-size: 11px;
    }

    #otpContainer {
        margin-top: 10px;
        width: 100%;
    }

    .otp-field-container-mc {
        width: 60%;
        margin: auto;
        margin-left: 35%;
       
    }

    .otp-inputs-mc {
        display: grid;
        grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
        gap: 7px;
    }

    .otp-field-mc {
        background: #1E1E1E;
        border: 1px solid #AAAAAA;
        border-radius: 5px;
        color: #AAAAAA;
        padding: 5px;
        height: auto;
        font-size: 12px;
    }

    .otp-field-mc::marker {
        border: #0F0;
    }

    .Button-mc {
        width: 100px;
        height: 30px;
        font-size: 12px;
    }

    input,
    select {
        height: 30px;
        width: 110px;
    }

    .inside-button-mc {
        margin-top: 10px;
        height: auto;
    }

    .Selectbox-mc {
        padding: 0px;
        width: 100%;
    }

    .Inputfield-mc {
        grid-template-columns: 0.7fr 0.1fr 1fr;
    }

    .Suggestion_area-mc h3 {
        font-size: 11px;
    }

    .Suggestion_area-mc span {
        color: #0F0;
    }
} */

@media (min-width: 320px) and (max-width: 454px) {

    /* .video_container iframe {
        height: 40vh;
        width: 100%;
        border-radius: 0;
    } */

    .Headerm {
        margin-left: 0;
    }

    .Image_containerm img {
        padding-left: 8%;
    }

    .Section_1 {
        width: 90%;
        padding-top: 60px !important;
    }

    .Mid_Text {
        width: 90vw;
    }

    .Mid_Text h1 {
        font-size: 40px;
    }

    .Mid_Text h2 {
        font-size: 16px;
    }

    .Mid_Text h3 {
        font-size: 14px;
    }

    .SVG_Container {
        margin-top: 130px;
    }

    .ScrollButton svg {
        width: 45px;
        height: 45px;
    }

    /* .Buttons {
        display: none;
    } */

    .enroll p {
        font-size: 18px;
    }

    .enroll h3 {
        font-size: 26px;
        font-weight: 800;
        margin-top: 10px;
    }

    .content {
        font-size: 16px;
        font-weight: 200;
    }

    .form-container-mc {
        width: 100%;
        margin: auto;
        margin-top: 40px;
        background: #1E1E1E;
        border-radius: 0px;
    }

    .form-mc {
        width: 90%;
        margin-top: 7px;
    }

    .form-input-mc {
        font-size: 14px;
    }

    .Inputfield-mc h3 {
        font-size: 14px;
        line-height: 20px;
    }

    .Inputfield-mc label {
        font-size: 12px;
    }

    .InputField_box-mc input,
    .Selectbox {
        width: 100%;
        height: 48px;
        font-size: 14px;
    }

    .Selectbox-mc {
        padding: 0px 8px;
        width: 100%;
        height: 48px;
        font-size: 14px;
        border-radius: 8px;
    }

    .InputField_box_email-mc input {
        width: 100%;
        height: 48px;
        font-size: 14px;
        padding-right: 65px;
    }

    .inside-button-mc {
        margin-top: 14px;
        margin-right: 8px;
        padding-right: 2px;
        font-size: 11px;
    }

    .containter1_otp p {
        background-color: #1E1E1E;
        font-size: 11px;
    }

    #otpContainer {
        margin-top: 10px;
        width: 100%;
    }

    .otp-field-container-mc {
        width: 60%;
        margin: auto;
        margin-left: 35%;
        /* MARGIN-TOP: 10PX;*/
    }

    .otp-inputs-mc {
        display: grid;
        grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
        gap: 7px;
    }

    .otp-field-mc {
        background: #1E1E1E;
        border: 1px solid #AAAAAA;
        border-radius: 5px;
        color: #AAAAAA;
        padding: 5px;
        height: auto;
        font-size: 12px;
        text-align: center;
    }

    .otp-field-mc::marker {
        border: 1px solid #0F0;
    }

    .otp-inputs-mc:focus {
        border: 1px solid #0F0;
    }

    .Button-mc {
        width: 180px;
        height: 40px;
        font-size: 14px;
        margin-top: 60px;
    }

    .buttonS-mc {
        margin-top: 20px;
    }

    /* .form-input,
    .Selectbox {
        padding: 10px;
    } */

    .Inputfield-mc {
        grid-template-columns: 0.35fr 0.1fr 1fr;
    }

    .Suggestion_area-mc h3 {
        font-size: 11px;
    }

    .Suggestion_area-mc span {
        color: #0F0;
    }
}

@media (min-width: 455px) and (max-width: 767px) {

    .Section_1 {
        width: 70%;
    }

    .Mid_Text {
        width: 330px;
    }

    .Mid_Text h1 {
        font-size: 40px;
    }

    .Mid_Text h2 {
        font-size: 19px;
    }

    .Mid_Text h3 {
        font-size: 10px;
    }

    .SVG_Container {
        margin-top: 30px;
    }

    .ScrollButton svg,
    .Buttons svg {
        width: 45px;
        height: 45px;
    }

    form {
        margin-top: 7px;
    }

    .video_container iframe {
        height: 40vh;
        width: 80%;
    }

    input,
    button {
        padding: 2px 5px;
    }

    .enroll p {
        font-size: 20px;
    }

    .enroll h3 {
        font-size: 48px;
    }

    .content {
        font-size: 16px;
    }


    .Inputfield-mc h3 {
        font-size: 14px;
    }

    .Inputfield-mc label {
        font-size: 14px;
    }

    .InputField_box-mc input,
    .Selectbox-mc {
        height: 50px;
        font-size: 16px;
    }

    .Button {
        width: 120px;
        height: 45px;
        font-size: 18px;
    }

    .form-container-mc {
        width: 90%;
    }

    .form-input-mc,
    .Selectbox-mc {
        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
    }

    .Inputfield-mc {
        grid-template-columns: 0.7fr 0.1fr 1fr;
    }

    .Suggestion_area-mc h3 {
        font-size: 14px;
    }

    textarea {
        font-size: 14px;
    }

    .otp-field-container-mc {
        width: 45%;
        margin: auto;
        margin-left: 50%;
        /* MARGIN-TOP: 10px; */
        background: #1E1E1E;
    }

    #otpContainer {
        margin-top: 10px;
        width: 100%;
    }

    /* .otp-field-container-mc {
        width: 50%;
        margin: auto;
        margin-left: 40%;
        MARGIN-TOP: 10PX;
    } */

    .otp-inputs-mc {
        display: grid;
        grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
        gap: 7px;
    }

    .otp-field-mc {
        background: #1E1E1E;
        border: 1px solid #AAAAAA;
        border-radius: 5px;
        color: #AAAAAA;
        padding: 5px;
        height: auto;
        font-size: 12px;
        text-align: center;
    }

    .otp-field-mc::marker {
        border: #0F0;
    }

    textarea .text-area-mc:focus {
        border: 1px solid #0F0;
        transition: border-bottom 0.5s ease;
        resize: none;
    }
}