input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Main {
  width: auto;
  height: auto;
  margin: auto;
  background-color: #010101;
}

@keyframes gradientAnimate {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: -100% 50%;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .Mid_Text h1 {
    font-size: 147px;
  }
}

.gradient-animation {
  background: linear-gradient(91deg, #00c800 16.1%, #0f0 85.26%);
  background-size: 200% 100%;
  animation: gradientAnimate 3s ease forwards;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.Header.HeaderFixed {
  width: 100%;
  top: 0;
  height: min(90px, 9.22vh);
  background-color: #010101;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 101;
  cursor: default;
  transition: top 0.3s ease;
}

.NormalScroll {
  width: auto;
  background-color: #010101;
  height: auto;
}

body::-webkit-scrollbar {
  display: none;
}

.Carousel::-webkit-scrollbar {
  width: 1px;
  background: transparent;
}

.Carousel::-webkit-scrollbar-thumb {
  background: transparent;
}

.Section_1,
.Section_2,
.Section_3,
.Section_4,
.Section_5 {
  height: 100vh;
  padding-top: 60px !important;
  scroll-behavior: smooth;
  position: relative;
  max-width: 1920px;
}

.Section_1 {
  width: 85%;
  height: 100vh;
  margin: auto;
  display: flex;
}

.Section_Contents {
  width: auto;
  height: auto;
  margin: auto;
}

.Mid_Text {
  width: 1162px;
  height: auto;
  margin: auto;
  display: grid;
}

.Mid_Text h1 {
  margin: 0;
  font-family: "Open Sans";
  font-size: 147px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.Mid_Text h2 {
  margin: 0;
  margin-top: 20px;
  color: #aaa;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Mid_Text h3 {
  color: #aaa;
  text-align: justify;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SVG_Container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: min(13.3vh, 130px);
}

.ScrollButton,
.Button_Left,
.Button_Right {
  width: auto;
  height: auto;
}

.ScrollButton:hover,
.Button_Left:hover,
.Button_Right:hover {
  cursor: pointer;
}

.Arrow_Down {
  fill: white;
  transform: translateY(0);
  transition: transform 1s;
  animation: colorChange 1s infinite;
}

@keyframes colorChange {
  0% {
    fill: white;
    transform: translateY(0);
  }

  50% {
    fill: #0f0;
    transform: translateY(5px);
  }

  100% {
    fill: white;
    transform: translateY(0);
  }
}

.ScrollButton:hover .Arrow_Down,
.Button_Right:hover .Arrow_Right,
.Button_Left:hover .Arrow_Left {
  fill: #0f0;
}

.Button_Right:hover .Arrow_Right {
  transform: translateX(5px);
  transition: transform 0.5s ease;
}

.Button_Left:hover .Arrow_Left {
  transition: transform 0.5s ease;
  transform: translateX(-5px);
}

.ScrollButton:hover .Circle,
.Button_Left:hover .Circle,
.Button_Right:hover .Circle {
  stroke: #0f0;
  stroke-width: 3;
}

.Section_2,
.Section_3,
.Section_4,
.Section_5 {
  width: 85%;
  margin: auto;
  padding-top: 140px;
  display: flex;
}

.Section_6,
.Section_7 {
  width: 85%;
  margin: auto;
  max-width: 1920px;

  padding-top: 120px;
}

.DivText1 {
  margin: 0 0 0 0;
  position: absolute;
  margin-top: 10px;
  color: rgba(0, 255, 0, 0.2);
  font-family: "Open Sans";
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.DivText2 {
  position: absolute;
  color: rgba(0, 255, 0, 0.2);
  font-family: "Open Sans";
  font-size: 120px;
  margin: 99vh 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  right: 0px;
}

.Block_A {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  max-width: 1920px;
}

.Block_A_Text {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-top: 50px;
}

.Block_A_Text p {
  width: min(44.2vw, 850px);
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: normal;
}

.Block_A_Text span {
  color: #0f0;
}

.Block_A_Point {
  width: 25vw;
  height: auto;
  display: flex;
  max-width: 800px;
  flex-direction: column;
}

.Block_A_Point h1 {
  color: #0f0;
  margin-bottom: 0;
  font-family: "Open Sans";
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
}

.Block_A_Point p {
  width: min(26vw, 500px);
  text-align: end;
  color: #6a6a6a;
  font-family: "Open Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
}

.LINE1 {
  right: 2%;
  position: absolute;
  width: 2px;
  height: 163vh;
  background-color: #0f0;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.LINE2 {
  left: 2%;
  position: absolute;
  width: 2px;
  height: 160vh;
  background-color: #0f0;
  animation: glow 2s infinite alternate;
}

.Block_B {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  margin-left: -100px;
}

.Block_B_Text {
  width: 35vw;
  height: auto;
  max-width: 800px;
}

.Block_B_Text p {
  width: min(41.66vw, 800px);
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: normal;
  margin-left: auto;
}

.Block_B_Text span {
  color: #0f0;
}

.Block_B_Point {
  width: auto;
  height: auto;
}

.Block_B_Point h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
}

.Block_B_Point p {
  width: min(26vw, 500px);
  color: #6a6a6a;
  font-family: "Open Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Block_C {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
}

.Block_C_Text {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-top: 50px;
}

.Block_C_Text p {
  width: min(44.2vw, 850px);
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: normal;
}

.Block_C_Text span {
  color: #0f0;
}

.Block_C_Point {
  width: 25vw;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.Block_C_Point h1 {
  color: #0f0;
  margin-bottom: 0;
  font-family: "Open Sans";
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
}

.Block_C_Point p {
  width: min(15.6vw, 300px);
  text-align: end;
  color: #6a6a6a;
  font-family: "Open Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
}

.Block_D {
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  margin-left: -100px;
}

.Block_D_Text {
  width: 35vw;
  height: auto;
  max-width: 800px;
}

.Block_D_Text p {
  width: min(41.6vw, 800px);
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: normal;
  margin-left: auto;
}

.Block_D_Text span {
  color: #0f0;
}

.Block_D_Point {
  width: auto;
  height: auto;
}

.Block_D_Point h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
}

.Block_D_Point p {
  width: min(26vw, 500px);
  color: #6a6a6a;
  font-family: "Open Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Image_Carousel {
  width: auto;
  height: auto;
  margin: auto;
}

.Heading1 {
  width: auto;
  height: auto;
}

.Heading1 h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Heading1 p {
  color: #aaa;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}

.Heading1 span {
  color: #0f0;
  text-align: justify;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Carousel_Container {
  width: auto;
  height: auto;
  margin: auto;
}

.Carousel_Container h3 {
  color: #6a6a6a;
  font-family: "Open Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 60px;
}

.Carousel {
  width: auto;
  height: auto;
  scroll-behavior: smooth;
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  gap: 2.3vw;
  margin-top: 60px;
}

.Carousel a {
  text-decoration: none;
  width: auto;
  height: auto;
}

.BOX {
  width: 500px;
  height: 520px;
  display: flex;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #4b4b4b;
  background: #121212;
  cursor: pointer;
}

.Buttons {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 70px;
}

.BOX_Container {
  width: auto;
  height: auto;
  margin: auto;
  display: grid;
  justify-content: center;
  gap: 20px;
}

.BOX_Container img {
  justify-self: center;
}

.BOX_Container h1 {
  color: #0f0;
  margin-top: 80px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: 1000px) and (max-width: 1350px) {
  .Mid_Text {
    width: 700px;
  }

  .Mid_Text h1 {
    font-size: 90px;
  }

  .Mid_Text h2 {
    font-size: 38px;
  }

  .Mid_Text h3 {
    font-size: 20px;
  }

  .Block_A_Point h1,
  .Block_C_Point h1,
  .Block_B_Point h1,
  .Block_D_Point h1 {
    font-size: 123px;
  }

  .Block_A_Point p,
  .Block_C_Point p,
  .Block_B_Point p,
  .Block_D_Point p {
    font-size: 26px;
  }

  .Block_A_Text p,
  .Block_B_Text p,
  .Block_C_Text p,
  .Block_D_Text p {
    width: 45vw;
    font-size: 18px;
    text-align: justify;
  }

  .DivText1,
  .DivText2 {
    font-size: 70px;
  }

  .Heading1 h1 {
    font-size: 46px;
  }

  .Heading1 p,
  .Heading1 span {
    font-size: 18px;
  }

  .Carousel_Container h3 {
    font-size: 30px;
  }

  .BOX {
    width: 350px;
    height: 430px;
  }

  .BOX_Container img {
    width: 100px;
  }

  .BOX_Container h1 {
    font-size: 25px;
  }
}

@media screen and (min-width: 800px) and (max-width: 999px) {
  .Mid_Text {
    width: 700px;
  }

  .Mid_Text h1 {
    font-size: 90px;
  }

  .Mid_Text h2 {
    font-size: 38px;
  }

  .Mid_Text h3 {
    font-size: 20px;
  }

  .Block_A,
  .Block_C {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .Block_B,
  .Block_D {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }

  .Block_A_Point h1,
  .Block_C_Point h1 {
    text-align: center;
    margin-left: 0;
    font-size: 123px;
  }

  .Block_B_Point h1,
  .Block_D_Point h1 {
    text-align: center;
    font-size: 123px;
  }

  .Block_A_Point p,
  .Block_C_Point p {
    width: auto;
    text-align: center;
    font-size: 26px;
  }

  .Block_B_Point p,
  .Block_D_Point p {
    width: auto;
    text-align: center;
    font-size: 26px;
  }

  .Block_B_Point,
  .Block_D_Point {
    width: auto;
  }

  .Block_C_Point,
  .Block_A_Point {
    width: auto;
  }

  .Block_A_Text,
  .Block_B_Text,
  .Block_C_Text,
  .Block_D_Text {
    width: auto;
  }

  .Block_A_Text p,
  .Block_B_Text p,
  .Block_C_Text p,
  .Block_D_Text p {
    width: 600px;
    font-size: 18px;
    text-align: justify;
  }

  .DivText1,
  .DivText2 {
    font-size: 70px;
  }

  .Heading1 h1 {
    font-size: 46px;
  }

  .Heading1 p,
  .Heading1 span {
    font-size: 18px;
  }

  .Carousel_Container h3 {
    font-size: 30px;
  }

  .BOX {
    width: 350px;
    height: 430px;
  }

  .BOX_Container img {
    width: 100px;
  }

  .BOX_Container h1 {
    font-size: 25px;
  }
}

@media screen and (min-width: 401px) and (max-width: 799px) {
  .Mid_Text {
    width: 330px;
  }

  .Mid_Text h1 {
    font-size: 40px;
  }

  .Mid_Text h2 {
    font-size: 19px;
  }

  .Mid_Text h3 {
    font-size: 10px;
  }

  .SVG_Container {
    margin-top: 30px;
  }

  .ScrollButton svg,
  .Buttons svg {
    width: 45px;
    height: 45px;
  }

  .Block_A,
  .Block_C {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .Block_B,
  .Block_D {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }

  .Block_A_Point h1,
  .Block_C_Point h1 {
    text-align: center;
    margin-left: 0;
    font-size: 73px;
  }

  .Block_B_Point h1,
  .Block_D_Point h1 {
    text-align: center;
    font-size: 73px;
  }

  .Block_A_Point p,
  .Block_C_Point p {
    width: auto;
    text-align: center;
    font-size: 20px;
  }

  .Block_B_Point p,
  .Block_D_Point p {
    width: auto;
    text-align: center;
    font-size: 20px;
  }

  .Block_B_Point,
  .Block_D_Point {
    width: auto;
  }

  .Block_C_Point,
  .Block_A_Point {
    width: auto;
  }

  .Block_A_Text,
  .Block_B_Text,
  .Block_C_Text,
  .Block_D_Text {
    width: auto;
  }

  .Block_A_Text p,
  .Block_B_Text p,
  .Block_C_Text p,
  .Block_D_Text p {
    width: 300px;
    font-size: 14px;
    text-align: justify;
  }

  .DivText1,
  .DivText2 {
    font-size: 70px;
  }

  .Heading1 h1 {
    font-size: 36px;
  }

  .Heading1 p,
  .Heading1 span {
    font-size: 14px;
  }

  .Carousel {
    margin-top: 40px;
  }

  .Carousel_Container h3 {
    font-size: 25px;
  }

  .BOX {
    width: 250px;
    height: 330px;
  }

  .BOX_Container img {
    width: 80px;
  }

  .BOX_Container h1 {
    font-size: 18px;
    margin-top: 40px;
  }
}

@media screen and (min-width: 260px) and (max-width: 400px) {
  .Section_1 {
    width: 90%;
    padding-top: 60px !important;
  }

  .Section_2,
  .Section_3,
  .Section_4,
  .Section_5,
  .Section_6,
  .Section_7 {
    width: 90%;
    height: auto;
  }

  .Mid_Text {
    width: 90vw;
  }

  .Mid_Text h1 {
    font-size: 40px;
  }

  .Mid_Text h2 {
    font-size: 16px;
  }

  .Mid_Text h3 {
    font-size: 14px;
  }

  .SVG_Container {
    margin-top: 130px;
  }

  .ScrollButton svg {
    width: 45px;
    height: 45px;
  }

  .Buttons {
    display: none;
  }

  .Block_A,
  .Block_C {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .Block_B,
  .Block_D {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }

  .Block_A_Point h1,
  .Block_C_Point h1 {
    text-align: center;
    margin-left: 0;
    font-size: 73px;
  }

  .Block_B_Point h1,
  .Block_D_Point h1 {
    text-align: center;
    font-size: 73px;
  }

  .Block_A_Point p,
  .Block_C_Point p {
    width: auto;
    text-align: center;
    font-size: 20px;
  }

  .Block_B_Point p,
  .Block_D_Point p {
    width: auto;
    text-align: center;
    font-size: 20px;
  }

  .Block_B_Point,
  .Block_D_Point {
    width: auto;
  }

  .Block_C_Point,
  .Block_A_Point {
    width: auto;
  }

  .Block_A_Text,
  .Block_B_Text,
  .Block_C_Text,
  .Block_D_Text {
    width: auto;
  }

  .Block_A_Text p,
  .Block_B_Text p,
  .Block_C_Text p,
  .Block_D_Text p {
    width: 300px;
    font-size: 14px;
    text-align: justify;
  }

  .LINE1 {
    right: 0.1%;
    width: 0.1px;
  }

  .LINE2 {
    left: 0.1%;
    width: 0.1px;
  }

  .DivText1,
  .DivText2 {
    font-size: 50px;
  }

  .Heading1 h1 {
    font-size: 30px;
  }

  .Heading1 p,
  .Heading1 span {
    font-size: 14px;
  }

  .Carousel {
    margin-top: 40px;
    gap: 13vw;
  }

  .Carousel_Container h3 {
    font-size: 25px;
  }

  .BOX {
    width: 250px;
    height: 330px;
  }

  .BOX_Container img {
    width: 80px;
  }

  .BOX_Container h1 {
    font-size: 18px;
    margin-top: 40px;
  }

  .DivText2 {
    margin: 80vh 0;
  }
}
