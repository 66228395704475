.passion img {
  width: 100%;
  height: auto;
  margin-top: 120px;
}

.photos_teams {
  justify-content: center;
}

.ourteam_cont {
  width: 85%;
  height: auto;
  margin: auto;
  max-width: 1920px;
}

.ourteams h1 {
  color: #0F0;
  font-family: 'Open Sans';
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.ourteams p {
  color: #AAA;
  text-align: justify;
  font-family: 'Open Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.44px;

}

.photos_cont {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 1920px;
}

.first_row,
.second_row,
.third_row {
  display: flex;
  gap: 5.7vw;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  justify-content: center;
}

.img1,
.img2,
.img3 {
  width: 400px;
  height: 420px;
  flex-shrink: 0;
  filter: grayscale(0%);
  transition: filter 0.3s ease-in-out;
  border-radius: 10px;
  transition: transform 0.8s;
}

.img_cont1 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 420px;
  width: 400px;
}

.Text {
  color: #010101;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.border {
  width: 280px;
  height: 40px;
  flex-shrink: 0;
  background-color: #0F0;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  text-align: center;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.8s ease-in;
  padding: 5px;
}

.description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  transform: translateY(100%);
  height: 420px;
  width: 400px;
}

/* .descrip_img {
  width: 400px;
  border-radius: 10px;
  height: 420px;
  flex-shrink: 0;
} */

.img_cont1:hover .description {
  transition: transform 1.2s ease;
  transform: translateY(0);
}

.img_cont1:hover .border {
  top: 110%;
  transition: 1.5s;
}

.img_cont1:hover .img1,
.img_cont1:hover .img3 {
  transform: scale(1.03);
  filter: grayscale(100%);
}

@media screen and (min-width:1121px) and (max-width:1625px) {
  .border {
    width: 220px;
    height: 28px;
    left: 50%;
  }

  .ourteam_cont {
    width: 90%;
  }

  .ourteams p {
    font-size: 20px;
    line-height: 32px;
  }

  .Text {
    font-size: 13px;
  }

  .description {
    width: 305px;
    height: 320px;
    display: flex;
  }

  .img1,
  .img2,
  .img3 {
    width: 300px;
    height: 320px;
  }

  .img_cont1 {
    width: auto;
    height: 320px;
  }

  .descrip_img {
    width: 100%;
    height: 320px;
  }

  .img_cont1:hover .description {
    transform: translateY(0);
  }
}

@media screen and (min-width:811px) and (max-width:1120px) {
  .border {
    width: 190px;
    height: 28px;
    left: 50%;
  }

  .Text {
    font-size: 13px;
  }

  .ourteam_cont {
    width: 90%;
  }

  .ourteams p {
    font-size: 18px;
    line-height: 28px;
  }

  .description {
    width: 200px;
    height: 220px;
    display: flex;
  }

  .img1,
  .img2,
  .img3 {
    width: 200px;
    height: 220px;
  }

  .img_cont1 {
    width: auto;
    height: 220px;
  }

  /* .descrip_img {
    width: 100%;
    height: 220px;
  } */

  .img_cont1:hover .description {
    transform: translateY(0);
  }
}

@media screen and (min-width:600px) and (max-width:810px) {
  .border {
    width: 130px;
    height: 25px;
    left: 50%;
    top: 85%;
  }

  .Text {
    font-size: 10px;
  }

  .ourteam_cont {
    width: 90%;
  }

  .ourteams p {
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    width: 140px;
    height: 160px;
    display: flex;
  }

  .img1,
  .img2,
  .img3 {
    width: 140px;
    height: 160px;
  }

  .img_cont1 {
    width: auto;
    height: 160px;
  }

  .descrip_img {
    width: 100%;
    height: 160px;
  }

  .img_cont1:hover .description {
    transform: translateY(0);
  }
}




@media screen and (min-width:300px) and (max-width:599px) {

  .ourteam_cont {
    width: 90%;
  }

  .border {
    width: 200px;
    height: 28px;
    top: 85%;
    padding: 5px;
  }

  .Text {
    font-size: 12px;
  }

  .ourteams p {
    font-size: 14px;
    line-height: normal;
    text-align: justify;
  }

  .ourteams h1 {
    font-size: 40px;
  }

  .img1,
  .img2,
  .img3 {
    height: 320px;
    width: 300px;
  }

  .img_cont1 {
    width: auto;
    height: 320px;
  }


  .first_row,
  .second_row,
  .third_row {
    display: grid;
    gap: 70px;
    justify-content: center;
  }

  .passion img {
    height: auto;
    margin-top: 100px;
  }

  .description {
    width: 300px;
    height: 320px;
    display: flex;
  }

  .descrip_img {
    width: 100%;
    height: 320px;
  }
}