footer {
  width: auto;
  height: auto;
  margin: auto;
  background: #1A1A1A;
}

.Footer_Elements {
  width: 85%;
  height: auto;
  margin: auto;
  padding-top: 60px;
  max-width: 1920px;
}

.Footer_Part_1 {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_Part_2 {
  width: auto;
  height: auto;
  margin-top: 40px;
}

.Footer_Part_2 h1 {
  color: #AAA;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: normal;
}

.Course {
  width: auto;
  height: auto;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.Course a {
  color: #FFF;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Footer_Part_3 {
  width: auto;
  height: auto;
  margin-top: 40px;
}

.Footer_Part_3 h1 {
  color: #AAA;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Footer_Part_3 span {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Contact {
  width: auto;
  height: auto;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.Social {
  width: 200px;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.content {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.content a {
  color: #0F0;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.Footer_Part_4 {
  width: auto;
  height: auto;
  margin-top: 40px;
  padding-bottom: 40px;
}

.linE {
  width: 100%;
  height: 1px;
  background: #414141;
}

.Footer_End {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
}

.Footer_End h6,
.Footer_End p,
.DOP_Section h6 {
  color: #AAA;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  margin: 0;
  margin-top: 20px;
  font-weight: 400;
  line-height: normal;
}


.Footer_End p {
  margin-left: auto;

}

.Footer_End a,
.DOP_Section a {
  text-decoration: none;
  color: #AAA;
}

@media screen and (min-width:1061px) and (max-width:1340px) {
  .Social {
    width: 150px;
  }

  .content a {
    font-size: 15px;
  }
}



@media screen and (min-width:260px) and (max-width:1060px) {
  .Footer_Elements {
    padding-top: 60px;
  }

  .Footer_Part_2 {
    margin-top: 60px;
  }

  .Footer_Part_2 h1 {
    font-size: 26px;
    text-align: center;
  }

  .Course {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    margin-top: 40px;
  }

  .Course a,
  .Footer_Part_3 span {
    font-size: 16px;
  }

  .Footer_Part_3 {
    margin-top: 60px;
  }

  .Footer_Part_3 h1 {
    font-size: 26px;
    text-align: center;
  }

  .Contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .Social {
    width: 50%;
    margin-top: 15px;
  }



  .content a {
    font-size: 12px;
  }

  .Footer_End {
    flex-direction: column;
  }

  .Footer_End h6 {
    font-size: 10px;
  }

  .Footer_End p {
    margin: auto;
    font-size: 10px;
    margin-top: 10px;
  }

  .Footer_End h6 {
    margin-top: 10px;
  }

  .Footer_Part_4 {
    padding-bottom: 10px;
  }
}