input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Main {
  width: auto;
  height: auto;
  background-color: #010101;
}

.Image_Top {
  width: 100%;
  height: 707px;
  background-color: #010101;
  display: grid;
  justify-content: center;
}

.Image_Top h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.Image_Top p {
  width: 60%;
  margin: auto;
  color: #aaa;
  text-align: center;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Image_Top img {
  margin: auto;
  margin-top: 100px;
  width: 730px;
  height: 316px;
  display: block;
  object-fit: contain;
}

.Mid_Section {
  width: 85%;
  height: auto;
  margin: auto;
  max-width: 1920px;
}

.Mid_Section h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

.BuildtoLearn {
  color: #fff;
  cursor: pointer;
}

.Mid_Section h3 {
  width: 90%;
  color: #aaa;
  text-align: center;
  margin: auto;
  font-size: 20px;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.48px;
  margin-top: 40px;
}

.Popup_1 {
  width: 760px;
  height: 525px;
  position: fixed;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 5px 20px;
  background-color: #aaa;
  z-index: 200;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.Popup_1 h1 {
  text-align: justify;
  color: #010101;
  font-size: 20px;
  font-style: normal;
  font-weight: bolder;
  line-height: 35px;
  margin-top: 0px;
  letter-spacing: 0.48px;
}

.Popup_1 p {
  color: #010101;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-top: 35px;
  letter-spacing: 0.48px;
}

.Safepro_Carousel {
  width: auto;
  height: auto;
  display: grid;
  justify-content: center;
  max-width: 1920px;
  padding-bottom: 120px;
}

.Safepro_Carousel h1 {
  text-align: center;
  color: #aaa;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.64px;
}

.Carousel_Section {
  width: auto;
  height: auto;
  margin: auto;
  background-color: #010101;
}

.Image_carousel {
  width: min(85vw, 1632px);
  height: auto;
  display: flex;
  overflow: hidden;
}

iframe {
  width: min(20vw, 400px);
  height: min(22vh, 215px);
  border-radius: 20px;
  margin-top: 40px;
  border: 0;
}

.carousel-container {
  width: min(82.2vw, 1580px);
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
}

.image-carousel {
  overflow: hidden;
}

.carousel-inner {
  width: auto;
  display: flex;
  justify-content: center;
  margin-left: 2.6vw;
  gap: min(4.6vw, 90px);
  transition: transform 1s ease-in-out;
}

/* .right-button {
  margin-left: 40px;
} */

.left-button,
.right-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.left-button {
  margin-right: 1px;
}

.hidden {
  visibility: hidden;
}

[type="radio"] {
  display: none;
}

#slider {
  height: auto;
  margin-top: 50px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}

#slider img {
  width: 100%;
  height: auto;
}

#slider label {
  margin: auto;
  width: 599px;
  height: auto;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  overflow: hidden;
  -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

#s1:checked~#slide4,
#s2:checked~#slide5,
#s3:checked~#slide1,
#s4:checked~#slide2,
#s5:checked~#slide3 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-20vw, 4vh, -200px);
  z-index: 10;
}

#s1:checked~#slide5,
#s2:checked~#slide1,
#s3:checked~#slide2,
#s4:checked~#slide3,
#s5:checked~#slide4 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-10vw, 2vh, -100px);
  z-index: 20;
}

#s1:checked~#slide1,
#s2:checked~#slide2,
#s3:checked~#slide3,
#s4:checked~#slide4,
#s5:checked~#slide5 {
  box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 11px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0);
  opacity: 1.5;
  z-index: 30;
}

#s1:checked~#slide2,
#s2:checked~#slide3,
#s3:checked~#slide4,
#s4:checked~#slide5,
#s5:checked~#slide1 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(10vw, 2vh, -100px);
  z-index: 20;
}

#s1:checked~#slide3,
#s2:checked~#slide4,
#s3:checked~#slide5,
#s4:checked~#slide1,
#s5:checked~#slide2 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(20vw, 4vh, -200px);
  z-index: 10;
}

.Left,
.Right {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.buttons {
  margin: auto;
  justify-content: center;
  display: flex;
  margin-top: 500px;
  gap: 50px;
}

.Techready_carousel {
  width: auto;
  height: 725px;
  margin: auto;
  margin-top: 100px;
  max-width: 1920px;
}

.TechText {
  color: #aaa;
  text-align: center;
  margin-top: 50px !important;
}

.TechText span {
  color: #0f0;
}

.Brochure_Section {
  width: auto;
  height: 120px;
  display: flex;
  align-items: center;
  background-color: #1a1a1a;
}

.Brochure_Contents {
  width: 85%;
  height: auto;
  margin: auto;
  display: flex;
  max-width: 1920px;
  align-items: center;
}

.Brochure_Contents h1 {
  width: 100%;
  color: #0f0;
  text-align: center;
  font-size: min(1.45vw, 32px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Brochure_Contents img {
  width: 20px;
  height: 20px;
}

.Brochure_Button {
  display: flex;
  cursor: pointer;
  gap: 10px;
  width: min(20vw, 300px);
  height: 52px;
  padding: min(1vh, 10px) min(1.6vw, 32px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 28px;
  border: 1px solid #010101;
  background: #0f0;
  color: #010101;
  font-size: min(1.1vw, 22px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.Brochure_Button:hover {
  color: #0f0;
  background-color: #010101;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.Form_Block {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 1920px;
  padding-bottom: 120px;
}

.Forms {
  width: auto;
  height: auto;
  margin: auto;
  background-color: #010101;
}

.Form_Block h1 {
  color: #0f0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 120px;
}

form {
  width: 85%;
  height: auto;
  margin: auto;
  margin-top: 100px;
}

.Inputfield {
  width: auto;
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 0.25fr 0.1fr;
}

.Inputfield h3 {
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 150% */
}

.Inputfield span {
  color: #0f0;
}

.Inputfield label {
  color: #aaa;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 150% */
}

.form-input:focus,
.Selectbox:focus {
  outline: none;
  border: none;
  border-bottom: 0.1px solid #0f0;
  transition: border-bottom 0.5s ease;
  resize: none;
}

.buttonS {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}

.Button {
  width: 234px;
  height: 56px;
  color: #0f0;
  font-size: 24px;
  transition: color 0.5s ease, background-color 0.5s ease;
  font-family: "Open Sans";
  background-color: #010101;
  border-radius: 28px;
  border: 1px solid #0f0;
  font-weight: 700;
  cursor: pointer;
}

.Button:hover {
  background-color: #0f0;
  color: #010101;
  transition: color 0.5s ease, background-color 0.5s ease;
}

.carousel-container-mobile {
  display: none;
}

@media (min-width: 768px) and (max-width: 1000px) {
  .hidden {
    visibility: visible;
  }

  .Mid_Section h1 {
    font-size: 24px;
  }

  .Mid_Section h3 {
    font-size: 19px;
  }

  .Popup_1 h1 {
    font-size: 20px;
  }

  .buttons {
    margin-top: 375px;
  }

  .Techready_carousel {
    height: 550px;
  }

  .TechText {
    font-size: 24px;
  }

  #slider label {
    width: 450px;
    height: auto;
  }

  .Left,
  .Right {
    width: 30px;
    height: 30px;
  }

  .Image_Top h1 {
    margin-top: -50px;
    font-size: 20px;
  }

  .Image_Top img {
    width: 600px;
  }

  .Image_Top p {
    font-size: 18px;
  }

  .Form_Block h1 {
    font-size: 25px;
  }

  .Inputfield h3 {
    font-size: 20px;
  }

  .Inputfield label {
    font-size: 20px;
  }

  .InputField_box input,
  .Selectbox {
    width: 430px;
    height: 50px;
    padding: 10px 15px;
  }

  .carousel-container {
    display: none;
  }

  .carousel-container-mobile {
    width: 700px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
  }

  iframe {
    width: 420px;
    height: 240px;
  }

  .carousel-inner {
    margin-left: 9.6vw;
    gap: 220px;
  }

  .right-button {
    margin-left: -3vw;
  }
}

@media (min-width: 455px) and (max-width: 767px) {
  .hidden {
    visibility: visible;
  }

  .Image_Top h1 {
    margin-top: -100px;
    font-size: 20px;
  }

  .Image_Top img {
    width: 400px;
  }

  .Image_Top p {
    width: 90%;
    margin-top: -140px;
    font-size: 16px;
  }

  .Image_Bottom h1 {
    font-size: 20px;
    margin-top: 100px;
  }

  .Mid_Section h1 {
    margin-top: 0px;
    font-size: 19px;
  }

  .Mid_Section h3 {
    font-size: 14px;
    line-height: normal;
  }

  .Popup_1 {
    width: 455px;
    height: 500px;
  }

  .Popup_1 h1 {
    margin-top: 20px;
    font-size: 11.25px;
    line-height: 10px;
  }

  .Popup_1 p {
    font-size: 11px;
  }

  .buttons {
    margin-top: 285px;
  }

  .Techready_carousel {
    height: 450px;
  }

  .TechText {
    font-size: 19px;
  }

  #slider label {
    width: 300px;
    height: auto;
  }

  .Left,
  .Right {
    width: 30px;
    height: 30px;
  }

  .Brochure_Section {
    height: 80px;
  }

  .Brochure_Contents h1 {
    font-size: 11px;
  }

  .Brochure_Button {
    margin-left: 10vw;
    width: 150px;
    height: 40px;
    font-size: 10px;
  }

  .Form_Block h1 {
    font-size: 20px;
    margin-left: 17px;
    margin-top: 40px;
  }

  form {
    margin-top: 7px;
  }

  .Inputfield h3 {
    font-size: 14px;
  }

  .Inputfield label {
    font-size: 14px;
  }

  .InputField_box input,
  .Selectbox {
    height: 50px;
    font-size: 16px;
  }

  .Button {
    width: 120px;
    height: 45px;
    font-size: 18px;
  }

  .form-input,
  .Selectbox {
    padding: 10px;
  }

  .Inputfield {
    grid-template-columns: 0.7fr 0.1fr 1fr;
  }

  .carousel-container {
    display: none;
  }

  .carousel-container-mobile {
    width: 440px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
  }

  iframe {
    width: 250px;
    height: auto;
  }

  .carousel-inner {
    margin-left: 9.6vw;
    gap: 170px;
  }

  .right-button {
    margin-right: 2vw;
  }
}

@media (min-width: 345px) and (max-width: 454px) {
  .Tech {
    margin-left: 30px;
  }

  .Image_Top {
    height: 700px;
  }

  .hidden {
    visibility: visible;
  }

  .Image_Top h1 {
    font-size: 16px;
    margin-top: -100px;
  }

  .Image_Top img {
    width: 300px;
  }

  .Image_Top p {
    width: 90%;
    margin-top: -160px;
    font-size: 17px;
  }

  .Mid_Section {
    width: 90%;
  }

  .Mid_Section h1 {
    margin-top: 50px;
    font-size: 17px;
  }

  .Mid_Section h3 {
    font-size: 14px;
    line-height: normal;
    width: auto;
  }

  .Popup_1 {
    width: 345px;
    height: 500px;
  }

  .Popup_1 h1 {
    margin-top: 20px;
    font-size: 8px;
    line-height: 10px;
  }

  .Popup_1 p {
    font-size: 8px;
  }

  .buttons {
    margin-top: 250px;
  }

  .Techready_carousel {
    height: 400px;
  }

  .TechText {
    font-size: 17px;
  }

  #slider label {
    width: 200px;
    height: auto;
  }

  .Left,
  .Right {
    width: 30px;
    height: 30px;
  }

  .Brochure_Contents {
    width: 90%;
  }

  .Brochure_Section {
    height: 80px;
  }

  .Brochure_Contents h1 {
    font-size: 16px;
  }

  .Brochure_Button {
    margin-left: 8vw;
    width: 150px;
    height: 40px;
    font-size: 13px;
  }

  .Brochure_Button img {
    width: 10px;
    height: 10px;
  }

  .Form_Block h1 {
    font-size: 16px;
    margin-top: 40px;
  }

  form {
    width: 90%;
    margin-top: 7px;
  }

  .Inputfield h3 {
    font-size: 12px;
    line-height: 20px;
  }

  .Inputfield label {
    font-size: 12px;
  }

  .InputField_box input,
  .Selectbox {
    width: 100%;
    height: 20px;
    font-size: 12px;
  }

  .Button {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }

  .form-input,
  .Selectbox {
    padding: 10px;
  }

  .Inputfield {
    grid-template-columns: 0.35fr 0.1fr 1fr;
  }

  .carousel-container {
    display: none;
  }

  .carousel-container-mobile {
    width: 340px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
  }

  iframe {
    width: 175px;
    height: 200px;
  }

  .carousel-inner {
    margin-left: 9.6vw;
    gap: 130px;
  }

  .right-button {
    margin-right: 5vw;
  }
}

@media (min-width: 100px) and (max-width: 344px) {
  .hidden {
    visibility: visible;
  }

  .Tech {
    width: 120px;
    margin-left: 20px;
  }

  .HomeH {
    width: 25px;
    height: 25px;
  }

  .Image_Top {
    height: 700px;
  }

  .Image_Top h1 {
    font-size: 13px;
    margin-top: -100px;
  }

  .Image_Top img {
    width: 250px;
  }

  .Image_Top p {
    width: 70%;
    margin-top: -150px;
    font-size: 10px;
  }

  .Image_Bottom h1 {
    font-size: 12px;
    margin-top: 70px;
  }

  .Mid_Section h1 {
    margin-top: 50px;
    font-size: 12px;
  }

  .Mid_Section h3 {
    font-size: 10px;
  }

  .Popup_1 {
    width: 280px;
    height: 500px;
  }

  .Popup_1 h1 {
    margin-top: 20px;
    font-size: 7.25px;
    line-height: 10px;
    text-align: center;
  }

  .Popup_1 p {
    font-size: 7px;
  }

  .buttons {
    margin-top: 200px;
  }

  .Techready_carousel {
    height: 350px;
  }

  .TechText {
    font-size: 12px;
  }

  #slider label {
    width: 150px;
    height: auto;
  }

  .Left,
  .Right {
    width: 20px;
    height: 20px;
  }

  .Brochure_Section {
    height: 60px;
  }

  .Brochure_Contents h1 {
    font-size: 6px;
  }

  .Brochure_Button {
    margin-left: 8vw;
    width: 100px;
    height: 30px;
    font-size: 7px;
  }

  .Brochure_Button img {
    width: 10px;
    height: 10px;
  }

  .Form_Block h1 {
    font-size: 16px;
    margin-left: 17px;
    margin-top: 40px;
  }

  form {
    width: 90%;
    margin-top: 7px;
  }

  .Inputfield h3 {
    font-size: 7px;
  }

  .Inputfield label {
    font-size: 7px;
  }

  .InputField_box input,
  .Selectbox {
    width: 100px;
    height: 30px;
    font-size: 7px;
  }

  .Button {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }

  .Popup h1 {
    font-size: 28px;
    margin: auto;
  }

  .Popup h3 {
    font-size: 18px;
  }

  .CloseButton {
    top: -15px;
  }

  input,
  .Selectbox {
    padding: 10px;
  }

  .Inputfield {
    grid-template-columns: 0.7fr 0.1fr 1fr;
  }

  .carousel-container {
    display: none;
  }

  .carousel-container-mobile {
    width: 250px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
  }

  iframe {
    width: 100px;
    height: 200px;
  }

  .carousel-inner {
    margin-left: 9vw;
    gap: 60px;
  }

  .right-button {
    margin-right: 2vw;
  }
}