.firstsec_cont {
  width: 85%;
  height: auto;
  display: flex;
  margin: auto;
}

.firstsecleft,
.secondsec {
  margin-top: 150px;
  width: auto;
  height: auto;

}

.firstsecright {
  margin-top: 180px;
  margin-left: auto;
  position: relative;

}

.firstsecright iframe {
  filter: invert(100%);
  width: 600px;
  height: 380px;
  border-radius: 20px;
  /* pointer-events: none; */
  /* border: none; */
}

.getintouch {
  color: #0F0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.parafirst {
  color: #AAA;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.parafirst span {
  color: #0F0;
  animation: glow 2s alternate infinite;
}

.map {
  position: absolute;
  width: 597px;
  height: 377px;
  border-radius: 20px;
  padding: 0px; 
  border-width: 0px; 
  margin: 0px; 
  z-index: 10;
  margin-top: 40px;
  pointer-events: none;
  border: 3px solid #AAA;
  /* border: none */
  /* z-index: 1;
  position: absolute; 
  left: 50%; 
  top: 50%; 
  width: 100%; 
  will-change: transform; 
  transform: translate(0px, 0px); */
}

@keyframes glow {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.contacts_cont {
  margin-top: 30px;
}

.contacts {
  display: flex;
  gap: 16px;
  align-items: center;
}

.contacts a {
  text-decoration: none;
}

.contacts p {
  color: #AAA;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contacts img {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.secondsec_cont {
  max-width: 1920px;
  width: 85%;
  height: auto;
}

.Reachus {
  color: #0F0;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width:600px) and (max-width:1150px) {
  .firstsec_cont {
    display: grid;
    margin-top: auto;
  }

  .firstsecright {
    margin-right: auto;
    margin-left: auto;
    margin-top: 150px;
  }

  .firstsecright iframe {
    width: 82vw;
  }

  /* .map{
    width: 81.5vw;
  } */

  .map{
    width: 100%;
    height: 253px;
    border-width: 1px;
    border: none
  }

}

@media screen and (min-width:260px) and (max-width:599px) {
  .firstsec_cont {
    width: 90%;
    display: grid;
    /* margin-bottom: 0; */
  }

  .firstsecleft {
    margin-top: 100px;
  }

  .firstsecright {
    margin-top: 10px;
    margin-left: 0;
  }

  .firstsecright iframe {
    width: 100%;
    height: 250px;
  }

  .map{
    width: 100%;
    height: 253px;
    border-width: 1px;
    border: none
  }

  .getintouch {
    font-size: 30px;
  }

  .parafirst {
    font-size: 14px;
  }

  .contacts p {
    font-size: 18px;
  }
}