.Paypage {
    width: 100vw;
    height: 100vh;
    background-color: #010101;
    display: flex;
    justify-content: center;
    align-items: center;
}

.P_Contents {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.P_Contents img {
    width: 440px;
    height: 64px;
}

.Image_Go {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Image_Go a {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

}

.P_Contents h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.PayPreview {
    width: 50%;
    height: 50%;
    border-radius: 40px;
    background-color: #1A1A1A;
}

.Review {
    width: 100%;
    height: 95%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Review h1 {
    width: 95%;
    color: #AAA;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
}

.Fee_details {
    width: 85%;
    margin: auto;
    height: auto;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 0.4fr 0.1fr 0.5fr;
}

.Fee_details h1,
.Fee_details h3,
.Fee_details p {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-align: start;
    margin-top: 10px;
}

.Pay_Button {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
}

.buttoN,
.buttoN1 {
    width: 200px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid #0F0;
    background-color: #1A1A1A;
    color: #0F0;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.buttoN1 {
    width: 300px;
}

.buttoN:hover,
.buttoN1:hover {
    background-color: #0F0;
    color: #1A1A1A;
    transition: color ease 0.5s, background-color ease 0.5s;
}

@media screen and (min-width:260px) and (max-width:1000px) {

    .PayPreview {
        width: 85%;
        height: 70%;
    }

    .P_Contents img {
        width: 250px;
    }

    .P_Contents h1 {
        font-size: 30px;
    }


    .Fee_details h1,
    .Fee_details h3,
    .Fee_details p {
        font-size: 16px;
        margin-top: 10px;
        line-height: normal;
        overflow: scroll;
    }

    .buttoN {
        width: 180px;
        font-size: 20px;
    }

    .buttoN1 {
        width: 250px;
        font-size: 20px;
    }

}