.Hire_from_us {
  width: 100vw;
  height: auto;
  background-color: #AAA;
  position: fixed;
  z-index: 100;
}

.H_F_U_Contents {
  width: 85%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  max-width: 1920px;
}

.Icons {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.HText {
  width: auto;
  height: auto;
  margin-left: auto;
}

.HText h1 {
  color: #101310;
  font-family: "Open sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width:260px) and (max-width:1070px) {


  .Hire_from_us {
    height: 30px;
  }

  .H_F_U_Contents {
    width: 95%;
    height: 36px;
    align-items: center;
  }

  .H_F_U_Contents img {
    width: 20px;
    height: 20px;
  }
}