.Image_Holder {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 250px;
}

.Text_Holder {
    width: 85%;
    height: auto;
    margin: auto;
    margin-top: 120px;
    max-width: 1920px;
}

.Text_Holder h1 {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Text_Holder p {
    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Animation_Holder {
    width: auto;
    height: auto;
    position: relative;
}

.Animation_Holder img {
    position: relative;
    z-index: 10;
}

.Circle_Animation {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 400px;
    border: 1px solid #0F0;
    background: rgba(0, 255, 0, 0.10);
    animation: Grow 2s ease infinite;
    z-index: 9;
    opacity: 0;
}

@keyframes Grow {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }

    75% {
        transform: scale(1.07);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.Tools_Holder {
    width: 85%;
    height: auto;
    margin: auto;
    margin-top: 90px;
    max-width: 1920px;
}

.Tools_Holder h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Tool_Container {
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    flex-wrap: wrap;
    column-gap: 120px;
    row-gap: 30px;
}

.Holder {
    width: 164px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;


}

.Holder p {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.Apply_button {
    width: 85%;
    height: auto;
    margin: auto;
    max-width: 1920px;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Apply_button a {
    text-decoration: none;
}

.Apply {
    width: 234px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid #0F0;
    background-color: #010101;
    color: #0F0;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.Apply_button button:hover {
    background-color: #0F0;
    color: #010101;
    transition: color ease 0.5s, background-color ease 0.5s;
}


@media screen and (min-width:561px) and (max-width:1080px) {
    .Image_Holder {
        padding-top: 200px;
    }

    .Image_Holder img {
        width: 300px;
        height: 300px;
    }

    .Circle_Animation {
        width: 300px;
        height: 300px;
    }

    .Apply {
        width: 140px;
        height: 40px;
        font-size: 12px;
    }

    .Text_Holder {
        margin-top: 70px;
    }

    .Text_Holder h1 {
        font-size: 26px;
    }

    .Text_Holder p {
        font-size: 18px;
        margin-top: 50px;
    }

    .Tools_Holder {
        margin-top: 70px;
    }

    .Tools_Holder h1 {
        font-size: 26px;
    }

    .Holder p {
        font-size: 19px;
    }

}

@media screen and (min-width:260px) and (max-width:560px) {

    .Text_Holder {
        width: 90%;
    }

    .Image_Holder {
        padding-top: 200px;
    }

    .Image_Holder img {
        width: 210px;
        height: 210px;
    }

    .Circle_Animation {
        width: 210px;
        height: 210px;
    }

    .Text_Holder {
        margin-top: 70px;
    }

    .Text_Holder h1 {
        font-size: 26px;
    }

    .Text_Holder p {
        font-size: 14px;

    }

    .Tools_Holder {
        width: 90%;
    }

    .Apply {
        width: 140px;
        height: 40px;
        font-size: 12px;
    }

    .Tool_Container {
        justify-content: center;
    }

    .Tools_Holder {
        margin-top: 70px;
    }

    .Tools_Holder h1 {
        font-size: 20px;
    }

    .Holder {
        width: 100px;
    }

    .Holder p {
        font-size: 14px;
    }

    .Holder img {
        width: 80px;
        height: 80px;
    }
}