.pdf-container {
    width: 210mm;
    height: 217mm;
    margin: auto;
    background-position: center center;
}

.PDF_Contents {
    width: 90%;
    height: auto;
    margin: auto;
}

.Logo_Container {
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 40px;
}

.From_Address {
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 24px;
}

.From_Address h1 {
    color: #101010;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.From_Address p {
    width: 40%;
    margin-top: 4px;
    color: #454545;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Contact_Details {
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 42px;
}

.Detail {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 20px;

}

.Detail h1 {
    color: #101010;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 0;
}

.Detail p {
    color: #454545;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 0;
}

.Dotted_Line {
    border-bottom: 3px dashed #AAAAAA;
    width: 100%;
    margin-top: 40px;
}

.Payment_Bill {
    width: auto;
    height: auto;
    display: flex;
    margin-top: 40px;
}

.Payment_Section {
    width: 70%;
    height: auto;

}

.Payment_Section h2,
.Bill_Section h2 {
    color: #101010;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Bill_Section {
    width: 30%;
    height: auto;
    margin-left: auto;
}

.Description_Section {
    width: 100%;
    height: 40px;
    background-color: #EBEBEB;
    margin-top: 80px;
    display: flex;
    align-items: center;
}

.Heading {
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
}

.Heading h1,
.Heading h2 {
    color: #101010;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;

}

.Heading h2 {
    margin-left: auto;
}

.Fee-Content {
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.Amount-Content {
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
}

.Fee-Content h1,
.Fee-Content h2 {
    color: #454545;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.Fee-Content h2,
.Amount-Content h2 {
    margin-left: auto;
}

.Amount_Box {
    width: 100%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    border-top: 0.5px solid #AAA;
    border-bottom: 0.5px solid #AAA;
    margin-top: 16px;

}

.Amount-Content h1,
.Amount-Content h2 {
    color: #101010;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Conclusion_Section {
    width: auto;
    height: auto;
    margin: auto;
    margin-top: 42px;
}

.Conclusion_Section h1 {
    color: #101010;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Reciept_Footer {
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    margin-top: 20px;
}

.Reciept_Footer p {
    color: #454545;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}