header {
  width: auto;
  height: auto;
  margin: auto;
}

.Image_container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.Header {
  width: 100%;
  height: min(90px, 9.22vh);
  background-color: #010101;
  display: flex;
  top: 40px;
  position: fixed;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 101;
  cursor: default;
  transition: top 0.3s ease;
}


.Header_Elements {
  width: 85%;
  height: min(90px, 9.22vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  max-width: 1920px;
}



.Navigation_Bar {
  width: auto;
  height: auto;
  display: flex;
  gap: 5px;
}

.Navigation_Bar a {
  text-align: center;
  padding: 14px 24px;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer !important;
  color: #AAA;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Mobile_Header {
  display: none;
}


.nav-bar:hover {
  color: #0F0;
}

.nav-bar.active {
  color: #0F0;

}


@media screen and (min-width:1301px) and (max-width:1525px) {

  .Header img {
    width: 150px;
  }

  .Navigation_Bar a {
    font-size: 15px;
  }

}

@media screen and (min-width:1071px) and (max-width:1300px) {

  .Header img {
    width: 150px;
  }

  .Navigation_Bar a {
    font-size: 10px;
  }

}


@media screen and (min-width:260px) and (max-width:1070px) {
  .Navigation_Bar {
    display: none;
  }

  .Header {
    top: 30px;
    z-index: 100;
    height: 56px;
  }

  .Header img {
    margin-left: 15px;
    width: 150px;
  }

  .Mobile_Header {
    width: auto;
    height: 56px;
    display: block;
    transition: display 0.5s ease;
  }

  .Horizontal_Menu {
    align-items: center;
    display: flex;
    width: auto;
    height: 100%;
    margin: auto;
    margin-right: 10px;
    transition: display 0.5 ease;
  }

  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }

  .line {
    fill: none;
    stroke: white;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }

  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .Mobile_Menu {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    margin-top: min(100px, 10.22vh);
    transition: display 0.5 ease;

  }

  .Menu {
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    background-color: #1a1a1a;
    margin-top: 15px;
    z-index: 999;
  }

  .Menu a {
    font-size: 16px;
    padding: 20px 20px;
    font-family: "Open Sans";
    color: #AAA;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid #010101;
  }
}