.Section_1 {
    display: flex;
    justify-content: center;
    width: 85%;
    height: 94vh;
    margin: auto;
    text-align: center;
}

.Section_Contents {
    width: auto;
    height: auto;
    margin: auto;
    /* margin-top: 27vh; */
}

.Mid_Text {
    width: 1162px;
    height: auto;
    margin: auto;
    display: grid;

}

.Mid_Text h1 {
    margin: 0;
    font-family: "Open Sans";
    font-size: 147px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
}

.Mid_Text h2 {
    margin: 0;
    margin-top: 20px;
    color: #AAA;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.Mid_Text h3 {

    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}


.gradient-animation {
    background: linear-gradient(91deg, #00C800 16.1%, #0F0 85.26%);
    background-size: 200% 100%;
    animation: gradientAnimate 3s ease forwards;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.Image_Holder_Landingpage {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;

}

.Text_Holder span {
    color: #fff;
}

.section_2 {
    margin-top: 0;
}

.Text_Holder {
    width: 85%;
    height: auto;
    margin: auto;
    margin-top: 120px;
    max-width: 1920px;
}

.Text_Holder h1 {
    margin-bottom: 10px;
    color: #AAA;
    font-family: "Open Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Text_Holder h2 {
    color: #00ff00;
    font-family: "Open Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
}

.Text_Holder h3 {
    margin: 0;
    color: #00ff00;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Text_Holder p {
    color: #AAA;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 30px;
}

.Animation_Holder_Landingpage {
    width: auto;
    height: auto;
    position: relative;
}

.Animation_Holder_Landingpage img {
    position: relative;
    z-index: 10;
}

.Circle_Animation_Landingpage {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 400px;
    border: 1px solid #0F0;
    background: rgba(0, 255, 0, 0.10);
    animation: Grow 2s ease infinite;
    z-index: 9;
    opacity: 0;
}

@keyframes Grow {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }

    75% {
        transform: scale(1.07);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.Apply_Now_button {
    height: auto;
    margin: auto;
    max-width: 1920px;
    display: flex;
    justify-content: left;
    font-weight: 700;
    /* margin-top: 0px; */
}


.Text_button_Holder {
    width: 80%;
    height: 90px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 50px;
}

.Text_button_Holder h1 {
    margin: 0;
    font-size: 40px;
    color: #6a6a6a;
    margin-bottom: 8px;
}

.Text_button_Holder .Apply_Now_button {
    margin: 0px;
}

.Apply_Now_button .Apply {
    width: auto;
    height: auto;
    flex-shrink: 0;
    border-radius: 28px;
    border: none;
    background-color: #0F0;
    color: #010101;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    padding: 8px 30px;
    font-weight: 900 !important;
    line-height: normal;
    cursor: pointer;
    transition: none;
}


.Ai_Developement {
    width: 85%;
    height: 450px;
    display: flex;
    margin: auto;
    margin-top: 120px;
    max-width: 1920px;
    justify-content: space-between;
}

.Ai_Developement .Text_Holder {
    width: 57%;
    margin-top: 0;
    margin-left: 0;
    display: block;
}

.Ai_Image {
    width: 35%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: right;
}

.Ai_Image img {
    width: 100%;
    max-width: 490px;
    max-height: 305px;
    height: 100%;
}

.Tools_Holder {
    width: 85%;
    height: auto;
    margin: auto;
    margin-top: 90px;
    max-width: 1920px;
}

.Tools_Holder h1 {
    color: #0F0;
    font-family: "Open Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Tool_Container {
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    flex-wrap: wrap;
    column-gap: 120px;
    row-gap: 30px;
}

.Holder {
    width: 164px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;


}

.Holder p {
    color: #AAA;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.Apply_button {
    width: 85%;
    height: auto;
    margin: auto;
    max-width: 1920px;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Apply_button a {
    text-decoration: none;
}

.Apply {
    width: 234px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid #0F0;
    background-color: #010101;
    color: #0F0;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.Apply_button button:hover {
    background-color: #0F0;
    color: #010101;
    transition: color ease 0.5s, background-color ease 0.5s;
}

.Form_1{
    margin-top: 70px;
}
@media screen and (min-width:1081px) and (max-width:1350px) {
    .Mid_Text {
        width: 700px;
    }

    .Mid_Text h1 {
        font-size: 90px;
    }

    .Mid_Text h2 {
        font-size: 38px;
    }

    .Mid_Text h3 {
        font-size: 20px;
    }
}

@media screen and (min-width:561px) and (max-width:1080px) {
    .Mid_Text {
        width: 700px;
    }

    .Mid_Text h1 {
        font-size: 90px;
    }

    .Mid_Text h2 {
        font-size: 38px;
    }

    .Mid_Text h3 {
        font-size: 20px;
    }

    .Text_Holder h2 {
        font-size: 40px;
        margin: 0px;
    }

    .Text_button_Holder {
        width: 100%;
        height: 56px;
        display: flex;
    }
    .Text_Holder h3 {
        font-size: 20px;
    }

    .Ai_Developement {
        width: 85%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 80px;
        max-width: 1080px;
        margin-bottom: 120px;
    }

    .Ai_Developement .Text_Holder {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        display: block;
    }

    .Ai_Image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .Ai_Image img {
        width: 100%;
        max-width: 400px;
        height: auto;
        margin: 35px;
    }

    .Image_Holder_Landingpage {
        padding-top: 60px;
    }

    .Image_Holder_Landingpage img {
        width: 300px;
        height: 300px;
    }

    .Circle_Animation_Landingpage {
        width: 300px;
        height: 300px;
    }

    .Apply_button {
        margin-top: 20px;
    }

    .Apply {
        width: 140px;
        height: 40px;
        font-size: 12px;
        font-weight: bold;
    }

    .Apply_Now_button .Apply {
        width: auto;
        height: auto;
        font-size: 12px;
        font-weight: bold;
    }

    .Text_Holder {
        margin-top: 50px;
    }

    .Text_Holder h1 {
        font-size: 26px;
    }

    .Text_Holder p {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .Tools_Holder {
        margin-top: 70px;
    }

    .Tools_Holder h1 {
        font-size: 26px;
    }

    .Holder p {
        font-size: 19px;
    }

    .Apply_Now_button .Animation_Holder_Landingpage {
        width: 140px;
        height: 40px;
        font-size: 12px;
    }

    .Image_Mod2 {
        display: block;
    }

    .Image_Mod1 {
        display: none;
    }

}
@media screen and (min-width:561px) and (max-width:699px) {
    .Mid_Text {
        width: 330px;
    }
 

    .Mid_Text h1 {
        font-size: 40px;
    }

    .Mid_Text h2 {
        font-size: 19px;
    }

    .Mid_Text h3 {
        font-size: 10px;
    }

}

@media screen and (min-width:260px) and (max-width:560px) {
    .Section_1 {
        display: flex;
        justify-content: center;
        width: 85%;
        min-height: 100dvh;
        margin: auto;
        text-align: center;

    }
    .Text_Holder h3 {
        font-size: 18px;
    }

    .Mid_Text {
        width: 90vw;
    }

    .Mid_Text h1 {
        font-size: 40px;
    }

    .Mid_Text h2 {
        font-size: 16px;
    }

    .Mid_Text h3 {
        font-size: 14px;
    }


    .Text_Holder {
        width: 90vw;
        margin-top: 20px;
    }

    .Text_Holder h2 {
        font-size: 30px;
        margin: 0;
    }


    .Text_button_Holder {
        width: 100%;
        height: 56px;
        display: flex;
        gap: 0;
        flex-direction: column;
    }

    .Ai_Developement {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 50px;
        max-width: 1080px;
        margin-bottom: 60px;
    }

    .Ai_Developement .Text_Holder {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        display: block;
    }

    .Ai_Image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .Ai_Image img {
        width: 90%;
        max-width: 300px;
        height: 200px;
        margin: 0;
        margin-top: 20px;
    }


    .Image_Holder_Landingpage {
        padding-top: 90px;
    }

    .Image_Holder_Landingpage img {
        width: 210px;
        height: 210px;
    }

    .Circle_Animation_Landingpage {
        width: 210px;
        height: 210px;
    }

    .Text_Holder {
        margin-top: 20px;
    }

    .Text_Holder h1 {
        font-size: 26px;
    }

    .Text_Holder p {
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 0;
    }

    .Tools_Holder {
        width: 90%;
    }

    .Apply_button {
        margin-top: 20px;
    }

    .Apply {
        width: 140px;
        height: 40px;
        font-size: 12px;
        margin-top: 12px;
    }
    .Apply_Now_button {
        justify-content: center;
    }

    .Apply_Now_button .Apply {
        width: auto;
        height: auto;
        font-size: 12px;
    }

    .Apply_Now_button .Animation_Holder_Landingpage {
        width: 140px;
        height: 40px;
        font-size: 12px;
    }

    .Tool_Container {
        justify-content: center;
        margin-top: 20px;
    }

    .Tools_Holder {
        margin-top: 70px;
    }

    .Tools_Holder h1 {
        font-size: 20px;
    }

    .Holder {
        width: 100px;
    }

    .Holder p {
        font-size: 14px;
    }

    .Holder img {
        width: 80px;
        height: 80px;
    }

    .Image_Mod2 {
        display: block;
    }

    .Image_Mod1 {
        display: none;
    }
    .Form_1{
        width: 100%;
    }
}